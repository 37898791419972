import { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from '../index';
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons';

const controlStyle = css`
  cursor: pointer;
  position: fixed;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
`;

const ImageGalleryStyled = styled.div`
  column-gap: 30px;
  column-fill: initial;

  /* Masonry item */
  & > span {
    margin-bottom: 30px;
    display: inline-block;
    vertical-align: top;
  }

  /* Masonry image effects */
  & > span > img {
    opacity: 1;
  }

  & > span:hover img {
    opacity: 1;
  }

  /* Bordered masonry */
  &.bordered {
    column-rule: 1px solid #eee;
    column-gap: 50px;
  }

  &.bordered > span {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #eee;
  }

  /* Gutterless masonry */
  &.gutterless {
    column-gap: 0;
  }

  &.gutterless > span {
    margin-bottom: 0;
  }

  /* Masonry on tablets */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    column-count: 2;
  }

  /* Masonry on big screens */
  @media only screen and (min-width: 1024px) and (max-width: 1399px) {
    column-count: 3;
  }

  /* Masonry on extra big screens */
  @media only screen and (min-width: 1400px) {
    column-count: 4;
  }
`

const GalleryTrigger = styled.img`
  width: 100%;
`

const ControlLeft = styled.span`
  ${controlStyle}
  left: 15px;
`;

const ControlRight = styled.span`
  ${controlStyle}
  right: 15px;
`;

const ItemDescription = styled.span`
  position: fixed;
  bottom: 15px;
  left: calc(50% - 175px);
  width: 350px;
  color: ${props => props.theme.colors.lightPrimary};
  text-align: center;

  & > span {
    color: ${props => props.theme.colors.primaryColor};
  }
`

const ImageGalleryOpenedImg = styled.img`
  max-width: 80vw;
  max-height: 80vh;
`;

const ImageGallery = ({ data }) => {
  const { t } = useTranslation();
  const theme = useSelector(state => state.settings.theme);
  const { primaryColor } = theme.colors;
  const [activeItem, setActiveItem] = useState(data[0]);

  return <ImageGalleryStyled>
    {data.map(val => {
      const indexOfActive = data.findIndex(item => item.src === activeItem.src);
      
      return <Modal
        trigger={
          <GalleryTrigger 
            src={val.src} 
            onClick={() => setActiveItem(val)}
          />
        }
      >
        <ControlLeft
          onClick={() => setActiveItem(indexOfActive !== 0 ? data[indexOfActive - 1] : data[indexOfActive])}
        >
          <ChevronLeftIcon width={40} height={40} fill={primaryColor} />
        </ControlLeft>
        <ImageGalleryOpenedImg src={activeItem.src} />
        <ControlRight
          onClick={() => setActiveItem(indexOfActive !== data.length - 1 ? data[indexOfActive + 1] : data[indexOfActive])}
        >
          <ChevronRightIcon width={40} height={40} fill={primaryColor} />
        </ControlRight>
        <ItemDescription><span>{indexOfActive + 1}</span> {t("gallery-counter-from")} <span>{data.length}</span> {t("gallery-counter-images")}</ItemDescription>
      </Modal>
    })}
    
  </ImageGalleryStyled>
}

export default ImageGallery;
