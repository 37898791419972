import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ButtonCustom } from '../index';
import { hexToRgb } from '../../functions';
import { useSelector } from 'react-redux';

const CollectionItemStyled = styled.article`
  width: 280px;
  margin: 0 auto 30px auto;
  background-image: ${props => props.$imgPath || 'unset'};
	background-size: cover;
	background-position: center center;
  -webkit-box-shadow: 0px 5px 15px -5px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .3)`};
  -moz-box-shadow: 0px 5px 15px -5px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .3)`};
  box-shadow: 0px 5px 15px -5px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .3)`};

  & > div {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    background-color: ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};
  }

  &:hover {
    transition: .4s;
    margin-top: -10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0px 10px 20px -10px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
    -moz-box-shadow: 0px 10px 20px -10px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
    box-shadow: 0px 20px 30px -15px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
  }

  @media (max-width: 992px) {
    &:hover {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
`;

const CollectionItemHeader = styled.div`
  position: relative;
  padding: 30px 15px;
  text-align: center;
  -webkit-box-shadow: inset 0px 50px 50px -20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0px 50px 50px -20px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 50px 50px -20px rgba(0, 0, 0, 0.5);

  h3 {
    margin: 0;
    color: ${props => props.theme.colors.primaryColor};
    font-weight: normal;
    font-size: 1.7rem;
    text-transform: uppercase;
  }

  &:before {
    content: '';
    position: absolute;
    width: 120px;
    height: 1px;
    background-color: ${props => props.theme.colors.primaryColor};
    left: calc(50% - 60px);
    bottom: 0;
  }
`;

const CollectionItemList = styled.ul`
  margin: 0;
  padding: 20px 0;
  list-style-type: none;
  text-align: center;

  li {
    color: ${props => props.theme.colors.lightPrimary};
    padding: 10px;

    &:hover {
      transition: .3s;
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;

const CollectionItemFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  text-align: center;
  -webkit-box-shadow: inset 0px -70px 70px -40px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0px -70px 70px -40px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px -70px 70px -40px rgba(0, 0, 0, 0.5);

  button:hover {
    transition: .3s;
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .85)`};
    -moz-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .85)`};
    box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .85)`};
  }

  &:before {
    content: '';
    position: absolute;
    width: 240px;
    height: 1px;
    background-color: ${props => props.theme.colors.primaryColor};
    left: calc(50% - 120px);
    top: 0;
  }
`;

const HomeCollectionItem = ({ title, list, imgPath }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useSelector(state => state.settings.theme);
  const { darkPrimary, primaryColor } = theme.colors;

  const handleClick = () => {
    navigate(`/shop`, {
      collectionType: title
    });
  };

  return (
    <CollectionItemStyled $imgPath={imgPath}>
      <div>
        <CollectionItemHeader>
          <h3>{title}</h3>
        </CollectionItemHeader>

        <CollectionItemList>
          {list.map((val, key) => <li key={key}>{val}</li>)}
        </CollectionItemList>

        <CollectionItemFooter>
          <ButtonCustom 
            as='button'
            backgroundColor={primaryColor} 
            color={darkPrimary} 
            textTransform='uppercase'
            padding='10px 25px'
            onClick={handleClick}
          >
            {t("home-collection-item-button")}
          </ButtonCustom>
        </CollectionItemFooter>
      </div>
    </CollectionItemStyled>
  );
};

export default HomeCollectionItem;
