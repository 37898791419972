import styled from 'styled-components';

const TextCustomStyled = styled.p`
  font-size: ${props => props.$size || 'inherit'};
  font-weight: ${props => props.$weight || 'inherit'};
  text-transform: ${props => props.$textTransform || 'inherit'};
  text-align: ${props => props.$align ?? 'inherit'};
  color: ${props => props.$color || 'inherit'};
  margin: ${props => props.$margin || `margin: ${props.$margin}`};
`;

const TextCustom = ({ as, size, weight, textTransform, color, margin, align, children }) => {
  return (
    <TextCustomStyled as={as ?? 'p'} $size={size} $weight={weight} $textTransform={textTransform} $color={color} $margin={margin} $align={align}>
      {children}
    </TextCustomStyled>
  );
}

export default TextCustom;
