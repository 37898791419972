import styled from "styled-components";
import { TextCustom } from "../index";
import { hexToRgb } from "../../functions";

const TimelineItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.primaryColor};

  &::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 20px;
    width: 1px;
    height: calc(100% - 20px);
    background-color: ${(props) => props.theme.colors.primaryColor};
  }

  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const TimelineItemHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -75px;

  h3 {
    position: relative;
    margin-bottom: 5px;

    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 1px;
      top: 20px;
      left: -30px;
      background-color: ${(props) => props.theme.colors.primaryColor};
    }
  }

  h4 {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-left: -60px;
  }
`;

const TimelineItemStyled = styled.article`
  position: relative;
  padding-left: 75px;
  margin-bottom: 15px;

  &:hover {
    & > div > div {
      transition: 0.3s;
      -webkit-box-shadow: 0px 0px 15px 5px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .75)`};
      -moz-box-shadow: 0px 0px 15px 5px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .75)`};
      box-shadow: 0px 0px 15px 5px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .75)`};
    }
  }

  @media (max-width: 768px) {
    padding-left: 60px;
  }
`;

const TimelineStyled = styled.div`
  width: 100%;

  & > article:last-of-type {
    & > div > div::before {
      display: none;
    }
  }
`;

const Timeline = ({ timelineData }) => {
  return (
    <TimelineStyled>
      {timelineData.map((item, index) => {
        return (
          <TimelineItemStyled key={`timeline-item-${index}`}>
            <TimelineItemHeaderStyled>
              <TimelineItemIcon>{item.icon}</TimelineItemIcon>
              <TextCustom as="h3" size="1.6rem">
                {item.title}
              </TextCustom>
            </TimelineItemHeaderStyled>
            <TextCustom as="h4" size="1rem">
              <em>{item.date}</em>
            </TextCustom>
            {item.content}
          </TimelineItemStyled>
        );
      })}
    </TimelineStyled>
  );
};

export default Timeline;
