import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TableSize = styled.table`
  width: 100%;
  border-collapse: collapse;

  &, th, td {
    border: 1px solid ${props => props.theme.colors.darkPrimary};
  }

  tr td:first-of-type {
    width: 95px;
    background-color: ${props => props.theme.colors.darkPrimary};
    color: ${props => props.theme.colors.lightPrimary};
    border-color: ${props => props.theme.colors.lightPrimary};
  }

  td {
    padding: 3px 5px;
  }

  tr:hover {
    td:first-of-type {
      transition: .3s;
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;

const DescriptionSweatshirtMan2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t("description-sweatshirt-man")}</p>
      <p>{t("description-material-global")}</p>
      <ul>
        <li>{t("description-cotton")} 95%</li>
        <li>{t("description-elastine")} 5%</li>
      </ul>
      <TableSize>
        <tr>
          <td>{t("description-size")}</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-sweatshirt-height")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>69</td>
          <td>71</td>
          <td>73</td>
          <td>75</td>
          <td>77</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-sweatshirt-width")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>50</td>
          <td>52</td>
          <td>54</td>
          <td>56</td>
          <td>58</td>
        </tr>
      </TableSize>
    </>
  );
};

export default DescriptionSweatshirtMan2;
