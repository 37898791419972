export const homeWelcomeSliderData = (t) => {
  return [
    {
      id: 0,
      title: t('home-welcome'),
      imgPath: '/images/home-cover.jpg',
    },
    {
      id: 1,
      title: t('home-welcome'),
      imgPath: '/images/shop-cover.jpg',
    },
		{
      id: 2,
      title: t('home-welcome'),
      imgPath: '/images/gallery-cover.jpg',
    },
  ];
};
