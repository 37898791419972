const PhoneIcon = ({ width, height, fill }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill}
      height={width ?? '1rem'}
      width={height ?? '1rem'}
    >
      <path
        fill={fill}
        d="M11 10c-1 1-1 2-2 2s-2-1-3-2-2-2-2-3 1-1 2-2-2-4-3-4-3 3-3 3c0 2 2.055 6.055 4 8s6 4 8 4c0 0 3-2 3-3s-3-4-4-3z"
      />
    </svg>
  );
}

export default PhoneIcon;
