import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col } from 'react-grid-system';
import { CollectionsPointer, ContainerCustom, Triangles, TextCustom, ImageCustom } from '../components/';

const CollectionsWrapper = styled.section`
  @media (max-width: 992px) {
    .womens-collection p, .womens-collection h1 {
      text-align: left;
    }

    .womens-collection > div > div {
      display: flex;
      flex-direction: column;
    }

    .womens-collection > div > div:nth-of-type(1) {
      order: 1;
    }
    .womens-collection > div > div:nth-of-type(2) {
      order: 3;
    }
    .womens-collection > div > div:nth-of-type(3) {
      order: 2;
    }
  }
`;

const Collections = () => {
  const theme = useSelector(state => state.settings.theme);
  const { t } = useTranslation();
  const { lightPrimary, primaryColor } = theme.colors;

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		
		return () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });;
  }, []);

  return (
    <CollectionsWrapper>
      {/* Collections - triangles */}
      <section className='section'>
        <Triangles 
          imgLeftUrl='../../images/triangles-womens-collection.jpg'
          imgCenterUrl='../../images/triangles-mens-collection.jpg'
          imgRightUrl='../../images/triangles-childrens-collection.jpg'
          contentLeftUrl={<CollectionsPointer title={t("collections-welcome-title-woman")} />}
          contentCenterUrl={<CollectionsPointer title={t("collections-welcome-title-man")} />}
          contentRightUrl={<CollectionsPointer title={t("collections-welcome-title-child")} />}
          backgroundPosition='center center'
          opacity={.4}
        />
      </section>

      {/* Collections - Men's collections */}
      <ContainerCustom padding='50px 0px' justify='between' bgColor={lightPrimary}>
        <Col xs={12}>
          <TextCustom as='h1' size='2.2rem'>
            <TextCustom as='span' color={props => props.theme.colors.primaryColor}>{t("collections-man-title-span")}</TextCustom> {t("collections-man-title")}
          </TextCustom>
        </Col>

        <Col lg={7} xl={8}>
          <TextCustom>{t("collections-man-paragraph-1")}</TextCustom>

          <TextCustom>{t("collections-man-paragraph-2")}</TextCustom>

          <TextCustom>{t("collections-man-paragraph-3")}</TextCustom>
        </Col>
        <Col lg={5} xl={3}>
          <ImageCustom src='/images/collections-man.jpg' alt='Valore Intimo - product patern' width='100%' borderColor={primaryColor} />
        </Col>
      </ContainerCustom>

      {/* Collections - Women's collections */}
      <ContainerCustom className='womens-collection' padding='50px 0px' justify='between' bgColor={props => props.theme.colors.darkPrimary} color={props => props.theme.colors.lightPrimary}>
        <Col xs={12}>
          <TextCustom as='h1' size='2.2rem' align='right'>
            <TextCustom as='span' color={props => props.theme.colors.primaryColor}>{t("collections-woman-title-span")}</TextCustom> {t("collections-woman-title")}
          </TextCustom>
        </Col>

        <Col lg={5} xl={3}>
          <ImageCustom src='/images/collections-women.png' alt='Valore Intimo - product patern' width='100%' borderColor={lightPrimary} />
        </Col>
        <Col lg={7} xl={8}>
          <TextCustom align='right'>{t("collections-woman-paragraph-1")}</TextCustom>

          <TextCustom align='right'>{t("collections-woman-paragraph-2")}</TextCustom>

          <TextCustom align='right'>{t("collections-woman-paragraph-3")}</TextCustom>
        </Col>
      </ContainerCustom>

      {/* Collections - Children's collections */}
      <ContainerCustom padding='50px 0px' justify='between' bgColor={props => props.theme.colors.primaryColor} color={props => props.theme.colors.darkPrimary}>
        <Col xs={12}>
          <TextCustom as='h1' size='2.2rem'>
            <TextCustom as='span' color={props => props.theme.colors.lightPrimary}>{t("collections-child-title-span")}</TextCustom> {t("collections-child-title")}
          </TextCustom>
        </Col>

        <Col lg={7} xl={8}>
          <TextCustom>{t("collections-child-paragraph-1")}</TextCustom>

          <TextCustom>{t("collections-child-paragraph-2")}</TextCustom>

          <TextCustom>{t("collections-child-paragraph-3")}</TextCustom>
        </Col>
        <Col lg={5} xl={3}>
          <ImageCustom src='/images/collections-child.jpg' alt='Valore Intimo - product patern' width='100%' />
        </Col>
      </ContainerCustom>
    </CollectionsWrapper>
  );
};
export default Collections;
