import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ButtonCustom, TextCustom, ImageCustom } from '../index';
import { hexToRgb } from '../../functions';

const BestSellerItemStyled = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  min-height: 375px;
  height: 100%;
  padding: 50px 25px;
  background-color: ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
  color: ${props => props.theme.colors.darkPrimary};
  text-align: center;

  article {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: 400px;
    height: 100%;

    img {
      max-height: 225px;
    }
  }

  h1 {
    padding: 0 5px;
  }

  button {
    margin-top: 20px;
    &:hover {
      transition: .3s;
      transform: scale(1.05);
      -webkit-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .85)`};
      -moz-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .85)`};
      box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .85)`};
    }
  }

  @media (max-width: 992px) {
    top: unset;
    bottom: 0;
    width: 100%;
    max-height: 500px;
  }

  &:hover {
    transition: .3s;
    background-color: ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .75)`};
  }

  &:hover > div {
    transition: .3s;
    border-color: transparent ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .75)`} transparent transparent;
  }
`;

const BestSellerTriangle = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: -40px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 40px 20px 0;
  border-color: transparent ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`} transparent transparent;

  @media (max-width: 992px) {
    top: -40px;
    left: calc(50% - 20px);
    transform: rotate(90deg);
  }
`;

const BestSellerItem = ({ title, linkPath, imgPath }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useSelector(state => state.settings.theme);
  const { darkPrimary, lightPrimary } = theme.colors;

  const handleClick = () => {
    navigate(linkPath);
  };

  return (
    <BestSellerItemStyled>
      <article>
        <TextCustom as='h1' size='2rem'>
          <TextCustom as='span' weight='bold'>{title}</TextCustom>
        </TextCustom>

        <div className='img-wrapper'>
          <ImageCustom src={imgPath} alt={`Valore Intimo - best seller - ${title}`} />
        </div>

        <div>
          <ButtonCustom 
            as='button'
            backgroundColor={darkPrimary} 
            color={lightPrimary} 
            textTransform='uppercase'
            padding='10px 25px'
            onClick={handleClick}
          >
            {t("home-best-seller-button")}
          </ButtonCustom>
        </div>
      </article>
      <BestSellerTriangle />
    </BestSellerItemStyled>
  );
};

export default BestSellerItem;
