const ChevronLeftIcon = ({ width, height, fill }) => {
    return (
      <svg
        viewBox="0 0 16 16"
        fill={fill}
        height={width ?? "1rem"}
        width={height ?? "1rem"}
      >
        <path
          fillRule="evenodd"
          d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
        />
      </svg>
    );
  };
  
  export default ChevronLeftIcon;
  