export const galleryData = [
  {
    id: 0,
    src: "../../images/gallery/valore-gallery-1.jpg",
  },
  {
    id: 1,
    src: "../../images/gallery/valore-gallery-2.jpg",
  },
  {
    id: 2,
    src: "../../images/gallery/valore-gallery-3.jpg",
  },
  {
    id: 3,
    src: "../../images/gallery/valore-gallery-4.jpg",
  },
  {
    id: 4,
    src: "../../images/gallery/valore-gallery-5.jpg",
  },
  {
    id: 5,
    src: "../../images/gallery/valore-gallery-6.jpg",
  },
  {
    id: 6,
    src: "../../images/gallery/valore-gallery-7.jpg",
  },
  {
    id: 7,
    src: "../../images/gallery/valore-gallery-8.jpg",
  },
  {
    id: 8,
    src: "../../images/gallery/valore-gallery-9.jpg",
  },
  {
    id: 9,
    src: "../../images/gallery/valore-gallery-10.jpg",
  },
  {
    id: 10,
    src: "../../images/gallery/valore-gallery-11.jpg",
  },
  {
    id: 11,
    src: "../../images/gallery/valore-gallery-12.jpg",
  },
	{
    id: 12,
    src: "../../images/gallery/valore-gallery-13.jpg",
  },
	{
    id: 13,
    src: "../../images/gallery/valore-gallery-14.jpg",
  },
	{
    id: 14,
    src: "../../images/gallery/valore-gallery-15.jpg",
  },
	{
    id: 15,
    src: "../../images/gallery/valore-gallery-16.jpg",
  },
	{
    id: 16,
    src: "../../images/gallery/valore-gallery-17.jpg",
  },
	{
    id: 17,
    src: "../../images/gallery/valore-gallery-18.jpg",
  },
	{
    id: 18,
    src: "../../images/gallery/valore-gallery-19.jpg",
  },
	{
    id: 19,
    src: "../../images/gallery/valore-gallery-20.jpg",
  },
	{
    id: 20,
    src: "../../images/gallery/valore-gallery-21.jpg",
  },
	{
    id: 21,
    src: "../../images/gallery/valore-gallery-22.jpg",
  },
	{
    id: 22,
    src: "../../images/gallery/valore-gallery-23.jpg",
  },
	{
    id: 23,
    src: "../../images/gallery/valore-gallery-24.jpg",
  },
	{
    id: 24,
    src: "../../images/gallery/valore-gallery-25.jpg",
  },
	{
    id: 25,
    src: "../../images/gallery/valore-gallery-26.jpg",
  },
	{
    id: 26,
    src: "../../images/gallery/valore-gallery-27.jpg",
  },
	{
    id: 27,
    src: "../../images/gallery/valore-gallery-28.jpg",
  },
	{
    id: 28,
    src: "../../images/gallery/valore-gallery-29.jpg",
  },
	{
    id: 29,
    src: "../../images/gallery/valore-gallery-30.jpg",
  },
	{
    id: 30,
    src: "../../images/gallery/valore-gallery-31.jpg",
  },
	{
    id: 31,
    src: "../../images/gallery/valore-gallery-32.jpg",
  },
	{
    id: 32,
    src: "../../images/gallery/valore-gallery-33.jpg",
  },
	{
    id: 33,
    src: "../../images/gallery/valore-gallery-34.jpg",
  },
	{
    id: 34,
    src: "../../images/gallery/valore-gallery-35.jpg",
  },
	{
    id: 35,
    src: "../../images/gallery/valore-gallery-36.jpg",
  },
	{
    id: 36,
    src: "../../images/gallery/valore-gallery-37.jpg",
  },
	{
    id: 37,
    src: "../../images/gallery/valore-gallery-38.jpg",
  },
	{
    id: 38,
    src: "../../images/gallery/valore-gallery-39.jpg",
  },
	{
    id: 39,
    src: "../../images/gallery/valore-gallery-40.jpg",
  },
	{
    id: 40,
    src: "../../images/gallery/valore-gallery-41.jpg",
  },
	{
    id: 41,
    src: "../../images/gallery/valore-gallery-42.jpg",
  },
	{
    id: 42,
    src: "../../images/gallery/valore-gallery-43.jpg",
  },
	{
    id: 43,
    src: "../../images/gallery/valore-gallery-44.jpg",
  },
	{
    id: 44,
    src: "../../images/gallery/valore-gallery-45.jpg",
  },
	{
    id: 45,
    src: "../../images/gallery/valore-gallery-46.jpg",
  },
	{
    id: 46,
    src: "../../images/gallery/valore-gallery-47.jpg",
  },
	{
    id: 47,
    src: "../../images/gallery/valore-gallery-48.jpg",
  },
	{
    id: 48,
    src: "../../images/gallery/valore-gallery-49.jpg",
  },
	{
    id: 49,
    src: "../../images/gallery/valore-gallery-50.jpg",
  },
	{
    id: 50,
    src: "../../images/gallery/valore-gallery-51.jpg",
  },
	{
    id: 51,
    src: "../../images/gallery/valore-gallery-52.jpg",
  },
	{
    id: 52,
    src: "../../images/gallery/valore-gallery-53.jpg",
  },
	{
    id: 53,
    src: "../../images/gallery/valore-gallery-54.jpg",
  },
	{
    id: 54,
    src: "../../images/gallery/valore-gallery-55.jpg",
  },
];
