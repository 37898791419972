const SendIcon = ({ width, height, fill }) => {
    return (
      <svg
        viewBox="0 0 512 512"
        fill={fill}
        height={width ?? '1rem'}
        width={height ?? '1rem'}
      >
        <path d="M16 464l480-208L16 48v160l320 48-320 48z" />
      </svg>
    );
  }
  
  export default SendIcon;
  