import { configureStore } from '@reduxjs/toolkit';
import cartReducer from '../redux-slices/cartSlice';
import settingsReducer from '../redux-slices/settingsSlice';
import snackbarReducer from '../redux-slices/snackbarSlice';

export default configureStore({
  reducer: {
    cart: cartReducer,
    settings: settingsReducer,
    snackbar: snackbarReducer,
  }
});