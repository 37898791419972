import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from "styled-components"
import GlobalStyle from './style/globalStyle'
import { 
  Home, AboutUs, Cart, Shop, Collections, Gallery, Contact,
  Error, SharedLayout, SharedShopLayout, ShopItem, TermsAndConditions
} from './pages/';
import { useSelector } from 'react-redux';
import './i18n/i18n';

function App() {
  const theme = useSelector(state => state.settings.theme);

  return <ThemeProvider theme={theme}>
    
    <GlobalStyle />

    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<SharedLayout />}>
          <Route exact index element={<Home />} />
          <Route exact path='about-us' element={<AboutUs />} />
          <Route exact path='cart' element={<Cart />} />
          <Route exact path='shop' element={<Shop />} />
          <Route exact path='shop/:productId' element={<ShopItem />} />
          <Route exact path='shop/:collectionType' element={<ShopItem />} />
          <Route exact path='collections' element={<Collections />} />
          <Route exact path='gallery' element={<Gallery />} />
          <Route exact path='contact' element={<Contact />} />
          <Route exact path='terms-and-conditions' element={<TermsAndConditions />} />

          <Route path='*' element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>

  </ThemeProvider>
}

export default App;
