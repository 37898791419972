import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TableSize = styled.table`
  width: 100%;
  border-collapse: collapse;

  &, th, td {
    border: 1px solid ${props => props.theme.colors.darkPrimary};
  }

  tr td:first-of-type {
    width: 95px;
    background-color: ${props => props.theme.colors.darkPrimary};
    color: ${props => props.theme.colors.lightPrimary};
    border-color: ${props => props.theme.colors.lightPrimary};
  }

  td {
    padding: 3px 5px;
  }

  tr:hover {
    td:first-of-type {
      transition: .3s;
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;

const DescriptionTShirtMan100PercentageCotton = () => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t("description-t-shirt-man")}</p>
      <p>{t("description-material-global")} <strong>100% {t("description-cotton")}</strong>.</p>
      <TableSize>
        <tr>
          <td>{t("description-size")}</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-t-shirt-height")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>67</td>
          <td>68</td>
          <td>75</td>
          <td>75</td>
          <td>80</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-t-shirt-width")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>50</td>
          <td>53</td>
          <td>56</td>
          <td>59</td>
          <td>63</td>
        </tr>
      </TableSize>
    </>
  );
};

export default DescriptionTShirtMan100PercentageCotton;
