import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Badge, ButtonCustom, TextCustom } from '../index';
import { hexToRgb } from '../../functions';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart } from '../../redux-slices/cartSlice';

const CartItemStyled = styled.article`
	display: inline-flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: calc(100% - 30px);
	background-color: white;
	margin-bottom: 15px;
	padding: 15px;
	border-radius: 5px;
	-webkit-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .2)`};
	-moz-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .2)`};
	box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .2)`};

	&:hover {
		transition: .3s;
		-webkit-box-shadow: 0px 0px 7px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
		-moz-box-shadow: 0px 0px 7px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
		box-shadow: 0px 0px 7px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
	}
`;

const CartItemImage = styled.div`
	width: 80px;
	height: 80px;
	margin-right: 15px;
	background-image: url(${props => props.$bgImage || 'unset'});
	background-size: cover;
	background-position: center center;
	-webkit-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
	-moz-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
	box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};

	@media (max-width: 470px) {
		width: 100%;
		height: 55vw;
		margin: 0;
	}
`;

const CartData = styled.div`
	flex-grow: 1;

	& > h3 {
		margin-bottom: 5px;
		font-weight: 500;
	}
`;

const CartDataArticle = styled.article`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;

	p {
		margin: 0 5px 5px 0;

		strong {
			font-weight: bold;
		}
	}

	@media (max-width: 768px) {
		justify-content: space-between;
	}
`;

const CartItemActions = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 110px;

	button {
		width: 100%;

		&:hover {
			transition: .3s;
			background-color: ${props => props.theme.colors.primaryColor};
			color: ${props => props.theme.colors.lightPrimary};
		}
	}

	@media (max-width: 1200px) {
		width: 100%;
		flex-direction: row;
		justify-content: space-between;

		button {
			width: 130px;
		}
	}

	@media (max-width: 991px) {
		width: 110px;
		flex-direction: column;
		justify-content: space-around;

		button {
			width: 100%;
		}
	}

	@media (max-width: 768px) {
		width: 100%;
		flex-direction: row;
		justify-content: space-between;

		button {
			width: 130px;
		}
	}
`;

const CartItem = ({ item }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useSelector(state => state.settings.theme);
	const selectedLanguage = useSelector(state => state.settings.localization.selectedLanguage);
  const { darkPrimary, lightPrimary } = theme.colors;

	const viewInShop = (itemId) => {
		navigate(`/shop/${itemId}`, {
			productId: 'itemId'
		});
	};

	const removeItemFromCart = (item) => {
    dispatch(removeFromCart(item));
  }

  return item && (
		<CartItemStyled>
			<CartItemImage $bgImage={item?.leadingImage} />
			<CartData>
				<TextCustom as='h3' size='1.3rem'>{item?.title}</TextCustom>
				<CartDataArticle>
					<TextCustom>{t("cart-card-collection")}: <Badge>{item?.collection}</Badge></TextCustom>
					<TextCustom>{t("cart-card-size")}: <Badge>{item?.selectedSize.toUpperCase()}</Badge></TextCustom>
				</CartDataArticle>
				<CartDataArticle>
					<TextCustom size='1.1rem'>
						{t("cart-card-price")}: 
						<TextCustom as='strong' size='1.2rem'>
							{selectedLanguage === 'en' ? 
								` € ${(item?.priceEURO * item?.selectedQuantity).toFixed(2)}` : 
								` ${(item?.priceRSD * item?.selectedQuantity).toFixed(2)} RSD`
							}
						</TextCustom>
					</TextCustom>
					<TextCustom size='1.1rem'>{t("cart-card-quantity")}: <TextCustom as='strong' size='1.2rem'>{item?.selectedQuantity}</TextCustom></TextCustom>
				</CartDataArticle>
			</CartData>
			<CartItemActions>
				<ButtonCustom
					as='button'
					backgroundColor={darkPrimary}
					color={lightPrimary}
					textTransform="uppercase"
					padding='7px 15px'
					size='.8rem'
					onClick={() => viewInShop(item?.id)}
				>
					{t("cart-card-view")}
				</ButtonCustom>
				<ButtonCustom
					as='button'
					backgroundColor={darkPrimary}
					color={lightPrimary}
					textTransform="uppercase"
					padding='7px 15px'
					size='.8rem'
					onClick={() => removeItemFromCart(item)}
				>
					{t("cart-card-remove")}
				</ButtonCustom>
			</CartItemActions>
		</CartItemStyled>
	);
};

export default CartItem;
