import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Portal } from '../index';
import { CloseIcon } from '../../icons';
import { useSelector } from 'react-redux';

const ModalTriggerStyled = styled.span`
	cursor: pointer;
	display: inline-block;
`;

const ModalWrapperStyled = styled.div`
	position: fixed;
	z-index: 100;
	inset: 0;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& > .shadow {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .85);
	}

	& > .content {
		z-index: 1;
		position: relative;

		& > .close {
			cursor: pointer;
			position: absolute;
			z-index: 1;
			top: 5px;
			right: 5px;
			width: 30px;
			height: 30px;
			border-radius: 15px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(0, 0, 0, .5);

			&:hover {
				transition: .3s;
				background-color: rgba(0, 0, 0, .8);
			}
		}
	}
`;

const Modal = ({ trigger, closeIcon, isVisible, children }) => {
	const theme = useSelector(state => state.settings.theme);
  const { lightPrimary } = theme.colors;
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (isVisible) {
			setShowModal(true);
		}
	}, [isVisible]);

  return (
		<>
			<ModalTriggerStyled onClick={() => setShowModal(true)}>{trigger}</ModalTriggerStyled>

			{showModal && <Portal id="modal">
				<ModalWrapperStyled>
					<div className='shadow'></div>
					<div className='content'>
						{children}
						<div className='close' onClick={() => setShowModal(false)}>
							{closeIcon || <CloseIcon width={20} height={20} fill={lightPrimary} />}
						</div>
					</div>
				</ModalWrapperStyled>
			</Portal>}
		</>
	);
}

export default Modal;
