const StoreSearchIcon = ({ width, height, fill }) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill={fill}
        height={width ?? '1rem'}
        width={height ?? '1rem'}
      >
        <path d="M11.9 10.9a6.53 6.53 0 00-1.9 4.6V12H4v4h6v-.5c0 .85.17 1.7.5 2.5H2v-6H1v-2l1-5h16l.89 4.46a6.47 6.47 0 00-6.99 1.44M18 2H2v2h16V2m5.39 19L22 22.39l-3.12-3.07c-.69.43-1.51.68-2.38.68-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5c0 .88-.25 1.71-.69 2.4l3.08 3.1M19 15.5a2.5 2.5 0 00-5 0 2.5 2.5 0 005 0z" />
      </svg>
    );
  }
  
  export default StoreSearchIcon;
  