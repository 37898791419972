import { createSlice } from '@reduxjs/toolkit';
import i18n from "i18next";
import { getStorageValue } from '../functions';

export const themeSlice = createSlice({
  name: 'settings',
  initialState: {
    theme: {
      colors: {
        primaryColor: getStorageValue('#daa524', 'selectedPrimaryColor'),
        darkPrimary: '#1e1e1e',
        lightPrimary: '#f6f6f6',
      },
    },
    localization: {
      selectedLanguage: getStorageValue('rs', 'selectedLanguage'),
    }
  },
  reducers: {
    updatePrimaryColor: (state, action) => {
			state.theme.colors.primaryColor = action.payload;
      localStorage.setItem('selectedPrimaryColor', JSON.stringify(action.payload));
    },
    updateSelectedLanguage: (state, action) => {
      i18n.changeLanguage(action.payload);
      state.localization.selectedLanguage = action.payload;
      localStorage.setItem('selectedLanguage', JSON.stringify(action.payload));
    },
  },
});

export const { updatePrimaryColor, updateSelectedLanguage } = themeSlice.actions;

export default themeSlice.reducer;
