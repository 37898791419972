import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { hexToRgb } from '../../functions';
import { ProductMark } from '../index'

const SliderItemStyled = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  & div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 180px;
    height: 180px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    & > span {
      text-align: center;
    }
  }

  h4 {
    margin: 15px 10px 0 10px;
    font-weight: normal;
    color: ${props => props.theme.colors.lightPrimary};
  }

  &:hover {
    & > div {
      transition: .5s;
      -webkit-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .75)`};
      -moz-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .75)`};
      box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .75)`};
    }

    & > h4 {
      transition: .5s;
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;

const SlickSliderHomeItem = ({ imgSrc, title, linkPath, mark }) => {
  return (
    <SliderItemStyled to={`/shop/${linkPath}`}>
      <div>
        {mark && <ProductMark>{mark}</ProductMark>}
        <img src={imgSrc} alt={`Valore Intimo - home carousel: ${title}`} />
      </div>
      <h4>{title}</h4>
    </SliderItemStyled>
  );
};

export default SlickSliderHomeItem;
