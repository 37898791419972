const ScissorsIcon = ({ width, height, fill }) => {
  return (
    <svg
      viewBox="0 0 15 15"
      fill={fill}
      height={width ?? '1rem'}
      width={height ?? '1rem'}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M.95 4.485a1.55 1.55 0 103.1 0 1.55 1.55 0 00-3.1 0zm1.55 2.45A2.45 2.45 0 114.773 5.4l.964.644a1.993 1.993 0 00-.02.068l-.154.55-.353.236-.994-.664c-.442.433-1.048.7-1.716.7zM.95 10.5a1.55 1.55 0 113.1 0 1.55 1.55 0 01-3.1 0zM2.5 8.05a2.45 2.45 0 102.277 1.545L15 2.757l-.951.1a10 10 0 00-3.818 1.208l-3.075 1.71a1 1 0 00-.476.606l-.253.906L4.224 8.76A2.442 2.442 0 002.5 8.05zm4.644 1.165l.012.007 3.075 1.71a10 10 0 003.818 1.208l.951.1L8.81 8.1 7.145 9.215z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ScissorsIcon;
