const ArrowBack = ({ width, height, fill }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill={fill}
      height={width ?? "1rem"}
      width={height ?? "1rem"}
    >
      <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" />
    </svg>
  );
};

export default ArrowBack;
