import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CartPointer, Footer, MainNavigation, ScrollToTopArrow } from '../components';
import { Snackbar } from '../components/';

const SharedLayout = () => {
  const snackbar = useSelector(state => state.snackbar.snackbarData);

  return (
    <>
      {snackbar.show && (
        <Snackbar 
          icon={snackbar.icon}
          message={snackbar.message}
          bgColor={snackbar.bgColor}
          color={snackbar.color}
          seconds={snackbar.seconds}
        />
      )}
      <MainNavigation />
      <Outlet />
      <Footer />
      <ScrollToTopArrow />
      <CartPointer />
    </>
  );
};

export default SharedLayout;
