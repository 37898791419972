import { useState, useEffect } from 'react';
import { BestSeller, BestSellerItem, SlickSliderHome, ContainerCustom, TextCustom, ImageCustom, HomeCollections, HomeWelcomeSlider } from '../components';
import { Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { hexToRgb } from '../functions';
import { useSelector } from 'react-redux';
import { shopData } from '../data';

const Home = () => {
  const { t } = useTranslation();
  const settings = useSelector(state => state.settings);
  const { lightPrimary, darkPrimary, primaryColor } = settings.theme.colors;

  const [bestSeller, setBestSeller] = useState();

  useEffect(() => {
    const shopDataItems = shopData(t);

    const bestSellerItem = shopDataItems.filter(val => val.id === '41');
    setBestSeller(bestSellerItem[0]);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		
		return () => window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [t]);

  return (
    <>
      {/* Home - welcome container */}
      <HomeWelcomeSlider />

      {/* Home - what is Valore Intimo */}
      <ContainerCustom padding='50px 0px' justify='between' bgColor={lightPrimary}>
        <Col xs={12}>
          <TextCustom as='h1' size='2.2rem'>
            {t('home-about-title')} <TextCustom as='span' color={primaryColor}>Valore Intimo</TextCustom> ?
          </TextCustom>
        </Col>

        <Col md={8} lg={9} xl={10}>
          <TextCustom>{t('home-about-paragraph-1')}</TextCustom>

          <TextCustom>{t('home-about-paragraph-2')}</TextCustom>

          <TextCustom>{t('home-about-paragraph-3')}</TextCustom>
        </Col>
        <Col md={4} lg={3} xl={2}>
          <ImageCustom src='/images/home-about.jpg' alt='Valore Intimo - product patern' width='100%' borderColor={primaryColor} />
        </Col>
      </ContainerCustom>

      {/* Home - top products */}
      <ContainerCustom 
        fluid={true}
        bgImage='/images/home-carousel-background.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        bgImageAttachment='fixed'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
        padding='50px 0px' 
        color={lightPrimary}
      >
        <Col>
          <ContainerCustom bgColor='transparent' color={lightPrimary}>
            <TextCustom as='h1' size='2.2rem'>
              {t('home-top-product-title')} <TextCustom as='span' color={primaryColor}>{t('home-top-product-title-span')}</TextCustom>
            </TextCustom>

            <SlickSliderHome />
          </ContainerCustom>
        </Col>
      </ContainerCustom>

      {/* Home - collections */}
      <ContainerCustom padding='50px 0px' justify='between' bgColor={lightPrimary}>
        <Col xs={12}>
          <TextCustom as='h1' size='2.2rem'>
            Valore Intimo <TextCustom as='span' color={primaryColor}>{t('home-collections-title-span')}</TextCustom>
          </TextCustom>

          <TextCustom>{t('home-collections-paragraph-1')}</TextCustom>
        </Col>

        <HomeCollections />
      </ContainerCustom>

      {/* Home - best seller */}
      <ContainerCustom 
        fluid={true}
        bgImage='/images/best-seller-background.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        bgImageAttachment='fixed'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
        padding='50px 0px' 
        color={lightPrimary}
      >
        <Col>
          <ContainerCustom bgColor='transparent' color={lightPrimary}>
            <TextCustom as='h1' size='2.2rem'>
              {t('home-best-seller-title')} <TextCustom as='span' color={primaryColor}>{t('home-best-seller-title-span')}</TextCustom>
            </TextCustom>

            <BestSeller />
          </ContainerCustom>
        </Col>

        {bestSeller && <BestSellerItem title={bestSeller.title} linkPath={`/shop/${bestSeller.id}`} imgPath={bestSeller.leadingImage} />}
      </ContainerCustom>
    </>
  );
};

export default Home;
