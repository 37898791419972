import styled from 'styled-components';

const ProductMarkStyled = styled.span`
	position: absolute;
	top: 0;
  display: inline-block;
	width: 75px;
  font-size: ${props => props.$size || '.7rem'};
	font-weight: bold;
  background-color: ${props => props.$backgroundColor || props.theme.colors.primaryColor};
  color: ${props => props.$color || props.theme.colors.darkPrimary};
  margin: ${props => props.$margin || '0 5px 0 0'};
  padding: ${props => props.$color || '3px 10px'};

	${props => props.$direction === 'left' ? 
		`
			transform: rotate(315deg);
			right: -26px;
			top: 12px;
		` : 
		`
			transform: rotate(45deg);
			right: -26px;
			top: 12px;
		`
	};
`

const ProductMark = ({ size, backgroundColor, color, padding, direction, children }) => {
  return <ProductMarkStyled $size={size} $backgroundColor={backgroundColor} $color={color} $padding={padding} $direction={direction}>
    {children}
  </ProductMarkStyled>
}

export default ProductMark;
