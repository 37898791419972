import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from 'axios';
import { ButtonCustom, CartItem, ContainerCustom, TextCustom, Modal } from '../components';
import { Col } from 'react-grid-system';
import { hexToRgb, validateEmail } from '../functions';
import { useSelector, useDispatch } from 'react-redux';
import { CartCheckoutIcon, SendIcon } from '../icons';
import { emptyCart } from '../redux-slices/cartSlice';
import { triggerSnackbar } from '../redux-slices/snackbarSlice';

const CartTitle = styled(Col)`
  &.active {
    position: sticky !important;
    top: 47px;
    z-index: 2;
    background-color: ${props => props.theme.colors.lightPrimary};
    padding: 15px 0;
    -webkit-box-shadow: 0px 20px 30px -20px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};
    -moz-box-shadow: 0px 20px 30px -20px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};
    box-shadow: 0px 20px 30px -20px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};

    h2 {
      margin: 0;
      padding: 10px 0;
    }
  }
`;

const CartActions = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    position: sticky !important;
    bottom: 0;
    z-index: 1;
    background-color: ${props => props.theme.colors.lightPrimary};
    -webkit-box-shadow: 0px -20px 30px -30px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px -20px 30px -30px rgba(0, 0, 0, 0.75);
    box-shadow: 0px -20px 30px -30px rgba(0, 0, 0, 0.75);

    button {
      margin: 10px 0;
    }
  }

  button {
    margin: 30px 0 0 0;

    &:hover {
      transition: .3s;
      transform: scale(1.05);
      background-color: ${props => props.theme.colors.primaryColor};
      color: ${props => props.theme.colors.darkPrimary};
      -webkit-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
      -moz-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
      box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
    }
  }
`;

const SendModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 10px;
  background-color: ${props => props.theme.colors.lightPrimary};
  border-radius: 6px;
  overflow-y: auto;

  & + div.close {
    right: 15px !important;
  }

  h2 {
    padding-bottom: 15px;
    -webkit-box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.2);
    box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.2);
  }

  .modal-content {
    max-height: calc(100vh - 170px);
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px #e6e6e6; 
      border-radius: 4px;
    }
    
    ::-webkit-scrollbar-thumb {
      cursor: pointer;
      background: ${props => props.theme.colors.darkPrimary};
      border-radius: 4px;
    }
  }

  .modal-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    -webkit-box-shadow: 0px -20px 30px -30px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px -20px 30px -30px rgba(0, 0, 0, 0.75);
    box-shadow: 0px -20px 30px -30px rgba(0, 0, 0, 0.75);

    button:hover {
      transition: .3s;
      transform: scale(1.05);
      background-color: ${props => props.theme.colors.primaryColor};
      color: ${props => props.theme.colors.darkPrimary};
      -webkit-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
      -moz-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
      box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
    }
  }

  @media (max-width: 550px) {
    width: calc(100% - 20px);
  }
`;

const SendModalContent = styled.div`
  padding: 15px;
`;

const SendModalFieldWrapper = styled.div`
  position: relative;
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 16px;

  label {
    margin-bottom: 10px;
  }

  input {
    width: calc(100% - 15px);
    padding: 5px;
    border: 1px solid #ddd;
  }

  & > p {
    position: absolute;
    bottom: -15px;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;

const SendModalFieldWrapperCheckbox = styled(SendModalFieldWrapper)`
  width: 100%;

  label {
    width: 100%;
    margin-bottom: 0;

    a {
      text-decoration: none;
      color: ${props => props.theme.colors.primaryColor};
    }
    
    input {
      width: auto;
      margin-right: 10px;
    }
  }
`;

const SendModalBoxOption = styled.div`
  width: 100%;
  margin: 10px 0;

  table {
    width: 100%;
    background-color: ${props => props.theme.colors.primaryColor};
    color: ${props => props.theme.colors.darkPrimary};
    border-collapse: collapse;

    thead {
      td {
        font-weight: bold;
      }
    }

    tbody {
      tr:last-of-type {
        border-bottom: none;

        td {
          font-weight: bold;
          font-size: 1.3rem;
        }
      }
    }

    tr {
      border-bottom: 1px solid ${props => props.theme.colors.darkPrimary};

      td:nth-of-type(2) {
        width: 140px;
        text-align: right;
      }
    }

    td {
      padding: 5px;
    }
  }
`;

const OrderSent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 220px;

  article {
    text-align: center;
  }

  h3 {
    margin-top: 15px;
  }

  .icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: ${props => props.theme.colors.darkPrimary};
    border-radius: 50px;
  }

  button:hover {
    transition: .3s;
    transform: scale(1.05);
    background-color: ${props => props.theme.colors.primaryColor};
    color: ${props => props.theme.colors.darkPrimary};
    -webkit-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
    -moz-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
    box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
  }
`;

const Cart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedLanguage = useSelector(state => state.settings.localization.selectedLanguage);
  const cartData = useSelector(state => state.cart.cartItems);
  const theme = useSelector(state => state.settings.theme);
  const { darkPrimary, lightPrimary, primaryColor } = theme.colors;
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [productsQuantity, setProductsQuantity] = useState(0);
  const [offset, setOffset] = useState(0);
  const [orderSent, setOrderSent] = useState(false);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);

  const [agreeTC, setAgreeTC] = useState(false);
  const [agreeTCError, setAgreeTCError] = useState(false);

  const postOrder = async () => {
    try {
      // const response = await axios.post("http://localhost:5000/api/sendorder", data);
      const language = selectedLanguage === 'en' ? '€' : 'RSD';
      const total = selectedLanguage === 'en' ? ` € ${totalPrice}` : ` ${totalPrice} RSD`;

      const response = await axios.get('https://api.valoreintimo.com/index.php?name=' + name + 
      '&email=' + email + 
      '&phone=' + phone +
      '&address=' + address +
      '&orderedProducts=' + JSON.stringify(cartItems) + 
      '&total=' + total +
      "&currency=" + language);



      setOrderSent(true);
      setName('');
      setPhone('');
      setEmail('');
      setAddress('');
      setAgreeTC(false);
      dispatch(emptyCart());
    } catch(error) {
      const snackbarInfo = {
        show: true,
        icon: 'error',
        message: error.message,
        bgColor: '#b30f0f',
        color: darkPrimary,
        seconds: 5,
      }
      dispatch(triggerSnackbar(snackbarInfo));
    }
  }

  useEffect(() => {
    setCartItems(cartData);

    let cartProductsQuantity = 0;
    let totalProductsPrice = 0;
    cartData.map(val => {
      cartProductsQuantity += parseInt(val.selectedQuantity)
      if (selectedLanguage === 'en') {
        totalProductsPrice += (parseInt(val.selectedQuantity) * parseFloat(val.priceEURO));
      } else {
        totalProductsPrice += (parseInt(val.selectedQuantity) * parseFloat(val.priceRSD));
      }
      return '';
    });
    setProductsQuantity(cartProductsQuantity);
    setTotalPrice(totalProductsPrice.toFixed(2));

    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);

		window.addEventListener('scroll', onScroll, { passive: true });
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  }, [cartData, selectedLanguage]);

  const sendOrder = async () => {
    const emailIsValid = validateEmail(email);

    if (name === '') {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (phone === '') {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }

    if (email === '') {
      setEmailError(true);
    } else {
      if (emailIsValid) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }

    if (address === '') {
      setAddressError(true);
    } else {
      setAddressError(false);
    }

    if (!agreeTC) {
      setAgreeTCError(true);
    } else {
      setAgreeTCError(false);
    }

    if (name !== '' && phone !== '' && email !== '' && address !== '' && emailIsValid && agreeTC) {
      postOrder();
    }
  }

  const goToHome = () => {
    navigate('/');
  }

  return (
    <>
      {/* Cart - welcome container */}
      <ContainerCustom 
        fluid={true}
        align='center'
        justify='center'
        bgImage='/images/about-cover.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        minHeight='50vh'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
        >
        <Col className='text-light text-center'>
          <TextCustom as='h1' size='2.5rem' margin='0 0 10px 0'>{t("cart-welcome-title")} <TextCustom as='span' color={primaryColor}>{t("cart-welcome-title-span")}</TextCustom></TextCustom>
          <TextCustom as='h3' size='1.05rem'>{t("cart-welcome-description")}</TextCustom>
        </Col>
      </ContainerCustom>

      {/* Cart - proceed */}
      <ContainerCustom padding='50px 0px' bgColor={lightPrimary}>
        <CartTitle className={offset > 280 ? 'active' : ''} xs={12}>
          <TextCustom as='h2' size='1.2rem'>{t("cart-info-title")} (<strong>{t("cart-info-title-bold")}: {productsQuantity}</strong>)</TextCustom>
        </CartTitle>
        {cartItems.length ? 
          <>
            {cartItems ? cartItems.map((item, key) => {
              return (
                <Col key={`cart-item-${key}`} xs={12} lg={6}>
                  <CartItem
                    item={item}
                  />
                </Col>
              );
            }) : (
              <p className='text-center'>{t("loading")}</p>
            )}
            {orderSent ? null : (
              <CartActions xs={12} className={offset > 280 ? 'active' : ''}>
                <Modal
                  trigger={(
                    <ButtonCustom 
                      as='button' 
                      textTransform='uppercase'
                    >
                      {t("cart-send-button")}
                    </ButtonCustom>
                  )}
                >
                  <SendModalWrapper>
                    <SendModalContent>
                      <>
                        <TextCustom as='h2' size='1.2rem'>
                          <SendIcon width={18} height={18} /> {t("order-modal-title")}
                        </TextCustom>

                        <div className='modal-content'>
                          <SendModalFieldWrapper>
                            <label>{t("order-modal-full-name-label")}</label>
                            <input 
                              name='name'
                              placeholder={t("order-modal-name-placeholder")} 
                              type='text'
                              value={name} 
                              maxLength={25}
                              onChange={e => setName(e.target.value)}
                            />
                            {nameError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("order-modal-name-error")}</TextCustom>}
                          </SendModalFieldWrapper>

                          <SendModalFieldWrapper>
                            <label>{t("order-modal-phone-label")}</label>
                            <input 
                              name='phone'
                              placeholder={t("order-modal-phone-placeholder")} 
                              type='text' 
                              value={phone} 
                              maxLength={25}
                              onChange={e => setPhone(e.target.value)}
                            />
                            {phoneError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("order-modal-phone-error")}</TextCustom>}
                          </SendModalFieldWrapper>

                          <SendModalFieldWrapper>
                            <label>{t("order-modal-email-label")}</label>
                            <input 
                              name='email'
                              placeholder={t("order-modal-email-placeholder")} 
                              type='email' 
                              value={email} 
                              maxLength={40}
                              onChange={e => setEmail(e.target.value)}
                            />
                            {emailError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("order-modal-email-error")}</TextCustom>}
                          </SendModalFieldWrapper>

                          <SendModalFieldWrapper>
                            <label>{t("order-modal-address-city-label")}</label>
                            <input 
                              name='address'
                              placeholder={t("order-modal-address-placeholder")} 
                              type='text'
                              value={address} 
                              maxLength={30}
                              onChange={e => setAddress(e.target.value)} 
                            />
                            {addressError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("order-modal-address-error")}</TextCustom>}
                          </SendModalFieldWrapper>

                          <SendModalFieldWrapperCheckbox>
                            <label>
                              <input type='checkbox' value={agreeTC} onChange={() => setAgreeTC(!agreeTC)} />
                              {t("order-modal-tc-label")} <NavLink to='/terms-and-conditions'>{t("order-modal-tc-label-link")}.</NavLink>
                            </label>
                            {agreeTCError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("order-modal-tc-error")}</TextCustom>}
                          </SendModalFieldWrapperCheckbox>

                          <SendModalBoxOption>
                            <table>
                              <thead>
                                <tr>
                                  <td>{t("order-modal-table-product")}</td>
                                  <td>{t("order-modal-table-price")}</td>
                                </tr>
                              </thead>
                              <tbody>
                                {cartItems.map((item, key) => {
                                  return (
                                    <tr key={`cart-send-modal-item-${key}`}>
                                      <td>{item.title}</td>
                                      <td>
                                        {item.selectedQuantity} * 
                                        {selectedLanguage === 'en' ? ` € ${item.priceEURO}` : ` ${item.priceRSD} RSD`}
                                      </td>
                                    </tr>
                                  )
                                })}
                                <tr>
                                  <td>
                                  {t("order-modal-table-total")}
                                  </td>
                                  <td>
                                    {selectedLanguage === 'en' ? ` € ${totalPrice}` : ` ${totalPrice} RSD`}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </SendModalBoxOption>
                        </div>

                        <div className='modal-action'>
                          <ButtonCustom 
                            as='button' 
                            textTransform='uppercase'
                            onClick={() => sendOrder()}
                          >
                            {t("order-modal-send-button")}
                          </ButtonCustom>
                        </div>
                      </>
                    </SendModalContent>
                  </SendModalWrapper>
                </Modal>
              </CartActions>
            )}
          </>
          :
          <Col xs={12}>
            <TextCustom as='h1' size='2.2rem'>
              {t("cart-empty")}
            </TextCustom>
          </Col>
        }
        
        {orderSent ? (
          <Modal
            isVisible={true}
          >
            <SendModalWrapper>
              <SendModalContent>
                <OrderSent>
                  <article>
                    <div className='icon-wrapper'>
                      <CartCheckoutIcon width="50px" height="50px" fill={primaryColor} />
                    </div>
                    <TextCustom as='h3' size='1.4rem'>{t("order-modal-send-title")}</TextCustom>
                    <TextCustom as='p' size='1rem' margin='0 0 15px 0'>{t("order-modal-send-description")}</TextCustom>
                  </article>
                  <ButtonCustom 
                    as='button' 
                    textTransform='uppercase'
                    onClick={() => goToHome()}
                  >
                    {t("order-modal-home-button")}
                  </ButtonCustom>
                </OrderSent>
              </SendModalContent>
            </SendModalWrapper>
          </Modal>
        ) : null}
      </ContainerCustom>
    </>
  );
};

export default Cart;
