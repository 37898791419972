const FilterMultipleIcon = ({ width, height, fill }) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill={fill}
        height={width ?? '1rem'}
        width={height ?? '1rem'}
      >
        <path d="M3.46 5c-.21 0-.42.08-.59.21-.44.34-.52.97-.18 1.4v.01L8 13.42v5.99l2.29 2.3c.39.39 1.03.39 1.42 0 .39-.39.39-1.03 0-1.42L10 18.59v-5.86L4.27 5.39A1.01 1.01 0 003.46 5M16 12v7.88c.04.3-.06.62-.29.83a.996.996 0 01-1.41 0l-2.01-2.01a.989.989 0 01-.29-.83V12h-.03L6.21 4.62a1 1 0 01.17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 01.17 1.4L16.03 12H16z" />
      </svg>
    );
  }
  
  export default FilterMultipleIcon;
  