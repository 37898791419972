import { createGlobalStyle, css } from 'styled-components';

export const fonts = css`
  @font-face {
    font-family: 'Segoe UI';
    src: url('../../public/fonts/segoe-ui/segoe-ui.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
  }
`;
 
const GlobalStyle = createGlobalStyle`
  // Fonts

  ${fonts}

  // Global style

  body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
  }

  // Textual classes

  .text-light {
    color: ${props => props.theme.colors.lightPrimary};
  }

  .text-dark {
    color: ${props => props.theme.colors.darkPrimary};
  }

  .text-primary {
    color: ${props => props.theme.colors.primaryColor};
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
`
 
export default GlobalStyle