import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Col } from 'react-grid-system';
import { ContainerCustom, TextCustom } from '../';
import { MailIcon, PhoneIcon, InstagramIcon, FacebookIcon, LinkedinIcon } from '../../icons/';
import { currentYear } from '../../data/';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const FooterStyled = styled.footer`
  a {
    color: ${props => props.theme.colors.lightPrimary};
    text-decoration: none;
    color: inherit;

    &:hover {
      transition: .3s;
      color: ${props => props.theme.colors.primaryColor};
    }
  }

  &:hover {
    h4 {
      transition: .3s;
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;

const FooterList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FooterListItem = styled.li`
  a {
    font-size: 1.15rem;
  }
`;

const FooterCopyright = styled.section`
  background-color: #121212;
  color: ${props => props.theme.colors.lightPrimary};
  text-align: center;
  font-size: .8rem;
  padding: 5px;
`;

const FooterSocialIconWrapper = styled.a`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 25px;
  background-color: ${props => props.theme.colors.lightPrimary};
  margin-right: 10px;

  ${props => props.$type === 'instagram' ?? `
    border: 2px solid red;
  `}
`;

const FooterSocialIcon = ({ icon, type, href, target }) => (
  <FooterSocialIconWrapper href={href} target={target} type={type}>
    {icon}
  </FooterSocialIconWrapper>
);

const Footer = () => {
  const theme = useSelector(state => state.settings.theme);
  const { lightPrimary, darkPrimary } = theme.colors;
  const { t } = useTranslation();

  const footerNavbarItems = [
    {
      route: '/',
      title: 'Home',
    },
    {
      route: '/about-us',
      title: t('page-about-us'),
    },
    {
      route: '/collections',
      title: t('page-collections'),
    },
    {
      route: '/shop',
      title: t('page-shop'),
    },
    {
      route: '/cart',
      title: t('page-cart'),
    },
    {
      route: '/gallery',
      title: t('page-gallery'),
    },
    {
      route: '/contact',
      title: t('page-contact'),
    },
  ];

  return (
    <FooterStyled>
      <ContainerCustom padding='100px 0px' justify='between' bgColor={props => props.theme.colors.darkPrimary} color={props => props.theme.colors.lightPrimary}>
        <Col md={4}>
          <TextCustom as='h4' size='1.5rem'>{t('footer-navigation-title')}</TextCustom>

          <FooterList>
            {footerNavbarItems.map((navbarItem, key) => {
              return <FooterListItem key={key}>
                <NavLink to={navbarItem.route} className={({isActive}) => isActive ? 'active' : ''}>
                  {navbarItem.title}
                </NavLink>    
              </FooterListItem>
            })}
          </FooterList>
        </Col>

        <Col md={4}>
          <TextCustom as='h4' size='1.5rem'>{t('footer-contact-us-title')}</TextCustom>

          <p><a href="mailto:shop@valoreintimo.com"><MailIcon width='15px' height='15px' fill={lightPrimary} /> shop@valoreintimo.com</a></p>
          <p><a href="tel:+381642009411"><PhoneIcon width='15px' height='15px' fill={lightPrimary} /> +38164 20 09 411</a></p>
        </Col>

        <Col md={4}>
          <TextCustom as='h4' size='1.5rem'>{t('footer-follow-us-title')}</TextCustom>

          <FooterSocialIcon href='https://www.instagram.com/valoreintimo/' target='_blank' icon={<InstagramIcon width='25px' height='25px' fill={darkPrimary} />} type='instagram' />

          <FooterSocialIcon href='https://sr-rs.facebook.com/valoreintimo/' target='_blank' icon={<FacebookIcon width='25px' height='25px' fill={darkPrimary} />} type='facebook' />

          <FooterSocialIcon href='https://www.linkedin.com/in/bojan-petrovic-44b468183/?originalSubdomain=rs' target='_blank' icon={<LinkedinIcon width='25px' height='25px' fill={darkPrimary} />} type='linkedin' />
        </Col>
      </ContainerCustom>

      <FooterCopyright>
        <NavLink to='/'>Home</NavLink> · 
        <NavLink to='/terms-and-conditions'> {t("tc-title")}</NavLink>
        <br />
        © Copyright 2018 - {currentYear} · <TextCustom as='span' color={props => props.theme.colors.primaryColor}>Valore Intimo</TextCustom> · {t('copyright')}
      </FooterCopyright>
    </FooterStyled>
  );
};

export default Footer;
