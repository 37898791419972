import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-grid-system';
import { HomeCollectionItem } from '../';
import { homeCollectionsData } from '../../data';

const HomeCollectionsStyled = styled(Container)`
  width: 100%;
  margin-top: 80px;

  & > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div > div:nth-of-type(2) {
    margin-top: -40px;
  }

  @media (max-width: 992px) {
    & > div > div:nth-of-type(2) {
      margin-top: 0;
    }
  }
`;

const HomeCollections = () => {
  const { t } = useTranslation();
  const [collectionsData, setCollectionsData] = useState([]);

  useEffect(()=> {
    const data = homeCollectionsData(t);
    setCollectionsData(data);
  }, [t]);

  return (
    <HomeCollectionsStyled>
      <Row align='justify'>
        {collectionsData.map((item, key) => <Col lg={4} key={key}>
          <HomeCollectionItem 
            title={item.title}
            list={item.list}
            imgPath={item.imgPath}
          />
        </Col>)}
      </Row>
    </HomeCollectionsStyled>
  );
};

export default HomeCollections;
