import { createSlice } from '@reduxjs/toolkit';
import getStorageValue from '../functions/getStorageValue';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartItems: getStorageValue([], 'cartItems'),
  },
  reducers: {
    addToCart: (state, action) => {
      let pushToArray = true;
			let newCartItems = state.cartItems;
      if (newCartItems.length !== 0) {
        newCartItems = newCartItems.map(val => {
          if (val.id === action.payload.id && val.selectedSize === action.payload.selectedSize) {
            val.selectedQuantity = (parseInt(val.selectedQuantity) + parseInt(action.payload.selectedQuantity));
            pushToArray = false;
          }
          return val;
        });
      }
      if (pushToArray) {
        newCartItems.push(action.payload)
      }
      state.cartItems = newCartItems;
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    },
    removeFromCart: (state, action) => {
      const payloadItem = action.payload;
      let cartItems = getStorageValue([], 'cartItems');
      const filteredCartItems = cartItems.filter((val) => JSON.stringify(val) !== JSON.stringify(payloadItem));
			state.cartItems = filteredCartItems;
      localStorage.setItem('cartItems', JSON.stringify(filteredCartItems));
    },
    emptyCart: (state) => {
      const emptyCartArray = [];
      state.cartItems = emptyCartArray;
      localStorage.setItem('cartItems', JSON.stringify(emptyCartArray));
    },
  },
});

export const { addToCart, removeFromCart, emptyCart } = cartSlice.actions;

export default cartSlice.reducer;