import styled from 'styled-components'

const ButtonCustomStyled = styled.a`
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-size: ${props => props.$size || 'inherit'};
  font-weight: ${props => props.$weight || 'inherit'};
  text-transform: ${props => props.$textTransform || 'inherit'};
  background-color: ${props => props.$backgroundColor || props.theme.colors.darkPrimary};
  color: ${props => props.$color || props.theme.colors.lightPrimary};
  padding: ${props => props.$padding || '15px 30px'};
  border-radius: ${props => props.$borderRadius || '50px'};
  border-width: 0;

  &:disabled {
    cursor: not-allowed;
    background-color: lightgray;
  }
`

const ButtonCustom = ({ as, disabled, className, onClick, targetLink, size, weight, textTransform, backgroundColor, color, padding, borderRadius, children }) => {
  return <ButtonCustomStyled disabled={disabled} className={className} onClick={onClick} as={as ?? 'a'} href={targetLink ?? '#'} $size={size} $weight={weight} $textTransform={textTransform} $backgroundColor={backgroundColor} $color={color} $padding={padding} $borderRadius={borderRadius}>
    {children}
  </ButtonCustomStyled>
}

export default ButtonCustom
