import {
  Coins,
  LightbulbOnIcon,
  ScissorsIcon,
  StarShootingIcon,
} from "../icons/";

export const timelineData = (t) => {
  let data = [
    {
      icon: <StarShootingIcon width="25px" height="25px" fill={"#1e1e1e"} />,
      title: t("about-us-timeline-item-1-title"),
      date: `April 2022`,
      content: t("about-us-timeline-item-1-description"),
    },
    {
      icon: <Coins width="25px" height="25px" fill={"#1e1e1e"} />,
      title: t("about-us-timeline-item-2-title"),
      date: t("about-us-timeline-item-2-date"),
      content: t("about-us-timeline-item-2-description"),
    },
    {
      icon: <ScissorsIcon width="25px" height="25px" fill={"#1e1e1e"} />,
      title: t("about-us-timeline-item-3-title"),
      date: t("about-us-timeline-item-3-date"),
      content: t("about-us-timeline-item-3-description"),
    },
    {
      icon: <LightbulbOnIcon width="25px" height="25px" fill={"#1e1e1e"} />,
      title: t("about-us-timeline-item-4-title"),
      date: t("about-us-timeline-item-4-date"),
      content: t("about-us-timeline-item-4-description"),
    },
  ];

  return data;
}
