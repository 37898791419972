import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { ButtonCustom } from '../index';
import { hexToRgb } from "../../functions";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const CollectionsContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 1.2rem;
    font-weight: 100;
  }

  button, a {
    padding: 5px 15px;

    &:hover {
      transition: .3s;
      transform: scale(1.02);
      background-color: ${(props) => props.theme.colors.primaryColor};
      -webkit-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
      -moz-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
      box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
    }
  }
`;

const CollectionsPointer = ({ title }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useSelector(state => state.settings.theme);
  const { lightPrimary, darkPrimary } = theme.colors;

  const goToShop = () => {
    navigate(`/shop/`);
  }

  return (
    <CollectionsContent>
      <h2>{title}</h2>
      <ButtonCustom
        backgroundColor={lightPrimary}
        color={darkPrimary}
        textTransform="uppercase"
        onClick={() => goToShop()}
      >
        {t("collections-welcome-button")}
      </ButtonCustom>
    </CollectionsContent>
  );
};

export default CollectionsPointer;
