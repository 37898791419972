export const homeCollectionsData = (t) => {
  return [
    {
      title: t("home-collection-item-man"),
      list: [t("home-collection-item-t-shirt"), t("home-collection-item-sweatsuit"), t("home-collection-item-sweatshirt"), t("home-collection-item-underwear")],
      imgPath: 'url("../../images/collections-man.jpg")',
    },
    {
      title: t("home-collection-item-woman"),
      list: [t("home-collection-item-t-shirt"), t("home-collection-item-sweatsuit"), t("home-collection-item-sweatshirt"), t("home-collection-item-underwear")],
      imgPath: 'url("../../images/collections-women.png")',
    },
    {
      title: t("home-collection-item-child"),
      list: [t("home-collection-item-t-shirt"), t("home-collection-item-sweatsuit"), t("home-collection-item-sweatshirt"), t("home-collection-item-underwear")],
      imgPath: 'url("../../images/collections-child.jpg")',
    },
  ];
};
