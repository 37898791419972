const WeatherPartlyLightingIcon = ({ width, height, fill }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill={fill}
      height={width ?? '1rem'}
      width={height ?? '1rem'}
    >
      <path d="M19 15a3 3 0 00-1 .17V15c0-1.81-.81-3.44-2.08-4.54.43-2.43-.82-4.96-3.17-5.99-2.78-1.23-6.03.03-7.25 2.78-.9 1.99-.5 4.2.77 5.75H6a4 4 0 00-4 4 4 4 0 004 4h1s1 0 1-1-1-1-1-1H6a2 2 0 01-2-2 2 2 0 012-2h2a4 4 0 014-4 4 4 0 014 4v2h3a1 1 0 011 1 1 1 0 01-1 1h-2s-1 0-1 1 1 1 1 1h2a3 3 0 003-3 3 3 0 00-3-3m-7-6c-1.84 0-3.5.83-4.59 2.13-.48-.91-.56-2.04-.1-3.06a3.487 3.487 0 014.62-1.77c1.25.56 2.01 1.76 2.07 3.04-.62-.22-1.3-.34-2-.34m1.55-6.37c-.55-.23-1.1-.4-1.67-.51l2.49-1.3.9 2.89c-.51-.42-1.08-.78-1.72-1.08m-7.46.81c-.49.35-.92.75-1.29 1.19l.11-2.81 2.96.68c-.62.21-1.22.53-1.78.94M18 8.71c-.09-.59-.22-1.16-.41-1.71l2.38 1.5-2.05 2.23c.11-.65.13-1.33.08-2.02M3.04 10.3c.07.6.21 1.17.39 1.7l-2.37-1.5L3.1 8.28c-.1.65-.13 1.33-.06 2.02M11.8 15h2.45l-1.64 3.27h1.64L11.18 24l.62-4.09H9.75" />
    </svg>
  );
}

export default WeatherPartlyLightingIcon;
