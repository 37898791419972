import { galleryData } from "./galleryData";
import { homeBestSellerData } from "./homeBestSellerData";
import { homeCollectionsData } from "./homeCollectionsData";
import { homeSliderData } from "./homeSliderData";
import { homeWelcomeSliderData } from "./homeWelcomeSliderData";
import { localizationData } from "./localizationData";
import { shopData } from "./shopData";
import { themeData } from "./themeData";
import { themeDataIcons } from "./themeDataIcons";
import { timelineData } from "./timelineData";

const date = new Date();
export const currentYear = date.getFullYear();

export {
  galleryData,
  homeBestSellerData,
  homeCollectionsData,
  homeSliderData,
  homeWelcomeSliderData,
  localizationData,
  shopData,
  themeData,
  themeDataIcons,
  timelineData,
};
