import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ImageGallery, ContainerCustom, TextCustom } from '../components';
import { Container, Col } from 'react-grid-system';
import { galleryData } from '../data';
import { hexToRgb } from '../functions';
import { useSelector } from 'react-redux';

const Wrapper = styled.section`
  .description {
    flex: unset !important;
    margin: 100px 0;
  }
`;

const Gallery = () => {
  const { t } = useTranslation();
  const theme = useSelector(state => state.settings.theme);
  const { darkPrimary, primaryColor } = theme.colors;

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		
		return () => window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return galleryData ? (
    <Wrapper>
      {/* Gallery - welcome container */}
      <ContainerCustom 
        fluid={true}
        align='center'
        justify='center'
        direction='column'
        bgImage='/images/gallery-cover.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        bgImageAttachment='fixed' 
        minHeight='100vh'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
        padding='0 15px'
      >
        <Col className='text-light text-center description'>
          <TextCustom as='h1' size='2.5rem' margin='0 0 10px 0'>Valore Intimo <TextCustom as='span' color={primaryColor}>{t("gallery-welcome-title-span")}</TextCustom></TextCustom>
          <TextCustom as='h3' size='1.05rem'>{t("gallery-welcome-description")}</TextCustom>
        </Col>
        <Container fluid padding='0 15px'>
          <ImageGallery data={galleryData} />
        </Container>
      </ContainerCustom>
    </Wrapper>
  ) : (
    <p className='text-center'>{t("loading")}</p>
  );
};

export default Gallery;
