import { createSlice } from '@reduxjs/toolkit'

export const snackbarEmptyObj = {
  show: false,
  icon: '',
  message: '',
  bgColor: '',
  color: '',
  seconds: 0,
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    snackbarData: snackbarEmptyObj,
  },
  reducers: {
    triggerSnackbar: (state, action) => {
			state.snackbarData = action.payload;
    },
  },
});

export const { triggerSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;