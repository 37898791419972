import { useCallback, useState } from "react";
import styled from "styled-components";
import { hexToRgb } from "../../functions";

const ListPickerWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	background-color: ${props => props.theme.colors.darkPrimary};
	color: ${props => props.theme.colors.lightPrimary};
	-webkit-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
	-moz-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
	box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};

	${props => props.$bgUrl && `
		color: transparent;
		background-image: url('${props.$bgUrl}');
		background-size: cover;
		background-position: center center;
	`};

	&:hover {
		transition: .3s;
		-webkit-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
		-moz-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
		box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85)`};
	}
`;

const ListPickerStyled = styled.ul`
	position: absolute;
	top: calc(100% + 5px);
	left: calc(50% - 20px);
	overflow: hidden;
	margin: 0;
	padding: 0;
	list-style-type: none;
	background-color: ${props => props.theme.colors.darkPrimary};
	color: ${props => props.theme.colors.lightPrimary};
	border-radius: 5px;

	li {
		cursor: pointer;
		width: 100px;
		padding: 10px;
		text-align: center;

		&.active {
			color: ${props => props.theme.colors.primaryColor};
		}

		&:hover {
			transition: .3s;
			background-color: ${props => props.theme.colors.primaryColor};
			color: ${props => props.theme.colors.darkPrimary};
		}
	}
`;

const ListTriangle = styled.span`
	position: absolute;
	bottom: -5px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 3px 6px 3px;
	border-color: transparent transparent ${props => props.theme.colors.darkPrimary} transparent;
`;

const ListPicker = ({ activeItem, listItems, listIconItems, onChange }) => {
	const [showList, setShowList] = useState(false);
	const [listActiveItem, setListActiveItem] = useState(activeItem);

	const onListClick = useCallback((e, newActiveItem) => {
		e.stopPropagation();
		setShowList(false);
		setListActiveItem(newActiveItem);
		onChange(newActiveItem);
	}, [onChange]);

  return (
		<ListPickerWrapper onClick={() => setShowList(!showList)} $bgUrl={listActiveItem?.flagUrl}>
			{listIconItems ? listIconItems.map((item, index) => {
				if (item.title === listActiveItem.title) {
					return <span key={`list-picker-icon-${index}`}>{item.icon}</span>;
				}
				return null;
			}) : listActiveItem.title}
			{showList ? <>
				<ListPickerStyled>
					{listItems.length && listItems.map(item => <li key={`list-picker-${item.value}`} onClick={(e) => onListClick(e, item)} className={listActiveItem.value === item.value ? 'active' : ''}>{item.title}</li>)}
				</ListPickerStyled>
				<ListTriangle />
			</> : null}
		</ListPickerWrapper>
	);
};

export default ListPicker;
