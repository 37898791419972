import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TableSize = styled.table`
  width: 100%;
  border-collapse: collapse;

  &, th, td {
    border: 1px solid ${props => props.theme.colors.darkPrimary};
  }

  tr td:first-of-type {
    width: 95px;
    background-color: ${props => props.theme.colors.darkPrimary};
    color: ${props => props.theme.colors.lightPrimary};
    border-color: ${props => props.theme.colors.lightPrimary};
  }

  td {
    padding: 3px 5px;
  }

  tr:hover {
    td:first-of-type {
      transition: .3s;
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;

const DescriptionUnderwearBrazilianWoman = () => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t("description-underwear-brazilian")}</p>
      <p>{t("description-material-global")}</p>
      <ul>
        <li>{t("description-cotton")} 95%</li>
        <li>{t("description-elastine")} 5%</li>
      </ul>
      <TableSize>
        <tr>
          <td>{t("description-size")}</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
        </tr>
        <tr>
          <td className='text-center' colspan='5'>{t("description-waist")}</td>
        </tr>
        <tr>
          <td>{t("description-inches")}</td>
          <td>24.5-26.5</td>
          <td>27-29</td>
          <td>29.5-32.5</td>
          <td>33-36</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>62-67</td>
          <td>68-73</td>
          <td>74-82</td>
          <td>83-91</td>
        </tr>
        <tr>
          <td className='text-center' colspan='5'>{t("description-hips")}</td>
        </tr>
        <tr>
          <td>{t("description-inches")}</td>
          <td>34.5-36.5</td>
          <td>37-39</td>
          <td>39.5-42.5</td>
          <td>43-46</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>87-92</td>
          <td>93-99</td>
          <td>100-108</td>
          <td>109-117</td>
        </tr>
      </TableSize>
    </>
  );
};

export default DescriptionUnderwearBrazilianWoman;
