import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { CartIcon } from "../../icons";
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../functions';

const CartPointerStyled = styled.a`
	${props => props.$offset > 50 && `
		cursor: pointer;
		position: fixed;
		z-index: 1;
		bottom: 15px;
		left: 15px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		text-decoration: none;
		background-color: rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85);
		-webkit-box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
		-moz-box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
		box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);

		&:hover {
			transition: .3s;
			transform: scale(1.05);
			-webkit-box-shadow: 0px 0px 5px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
			-moz-box-shadow: 0px 0px 5px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
			box-shadow: 0px 0px 5px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
		}

		&:hover > span {
			transition: .3s;
			transform: scale(1.05);
			-webkit-box-shadow: 0px 0px 5px 0px rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5);
			-moz-box-shadow: 0px 0px 5px 0px rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5);
			box-shadow: 0px 0px 5px 0px rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5);
		}
	`}
`;

const CartQuantity = styled.span`
	${props => props.$offset > 50 && `
		position: absolute;
		top: 0;
		right: 0;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 14px;
		height: 14px;
		border-radius: 7px;
		background-color: ${props.theme.colors.primaryColor};
		color: ${props.theme.colors.darkPrimary};
		font-size: .6rem;
		-webkit-box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5);
		-moz-box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5);
		box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5);
	`}
`;

const CartPointer = () => {
	const cartItemsQuantity = useSelector(state => state.cart.cartItems);
	const theme = useSelector(state => state.settings.theme);
  const { primaryColor } = theme.colors;
	const [productsQuantity, setProductsQuantity] = useState(0);

	const [offset, setOffset] = useState(0);

	useEffect(() => {
		let cartProductsQuantity = 0;
    cartItemsQuantity.map(val => cartProductsQuantity += parseInt(val.selectedQuantity));
    setProductsQuantity(cartProductsQuantity);

		const onScroll = () => setOffset(window.pageYOffset);
		// Clean up code
		window.removeEventListener('scroll', onScroll);
	
		window.addEventListener('scroll', onScroll, { passive: true });
		
		return () => window.removeEventListener('scroll', onScroll);
	}, [cartItemsQuantity]);

	return (
		<Link to='/cart'>
			<CartPointerStyled $offset={offset}>
				<CartQuantity $offset={offset}>{productsQuantity}</CartQuantity>
				<CartIcon width='20px' height='20px' fill={primaryColor} />
			</CartPointerStyled>
		</Link>)
	;
};

export default CartPointer;
