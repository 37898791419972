const SendMessageIcon = ({ width, height, fill }) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill={fill}
        height={width ?? '1rem'}
        width={height ?? '1rem'}
      >
        <path d="M2 21l21-9L2 3v7l15 2-15 2v7z" />
      </svg>
    );
  }
  
  export default SendMessageIcon;
  