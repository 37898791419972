import styled from 'styled-components';
import { Col } from 'react-grid-system';

const BestSellerDescriptionItemStyled = styled(Col)`
  display: flex;
`;

const ItemIconWrapper = styled.div`
  width: 60px;
  text-align: center;
`;

const ItemContent = styled.div`
  width: calc(100% - 60px);

  h3 {
    font-weight: normal;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
`;

const BestSellerDescriptionItem = ({ icon, title, description}) => {
  return (
    <BestSellerDescriptionItemStyled xl={6}>
      <ItemIconWrapper>
        {icon}
      </ItemIconWrapper>

      <ItemContent>
        <h3>{title}</h3>
        <p>{description}</p>
      </ItemContent>
    </BestSellerDescriptionItemStyled>
  );
};

export default BestSellerDescriptionItem;
