import { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col } from 'react-grid-system';
import { SlickSliderHomeItem, ContainerCustom, TextCustom } from "../";
import { homeWelcomeSliderData } from "../../data/";
import { hexToRgb } from "../../functions";

const SliderStyled = styled(Slider)`
	width: 100%;

	.slick-arrow {
		display: none !important;
	}
	.slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
	
	.content-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

const HomeWelcomeSlider = () => {
	const settings = useSelector(state => state.settings);
  const { lightPrimary, darkPrimary, primaryColor } = settings.theme.colors;
	const { t } = useTranslation();
	const [sliderData, setSliderData] = useState([]);

  const sliderSettings = {
		dots: false,
		fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

	useEffect(() => {
		const welcomeSliderData = homeWelcomeSliderData(t);
		setSliderData(welcomeSliderData);
	}, [t]);

  return sliderData && (
		<SliderStyled {...sliderSettings}>
			{sliderData.map((item, key) => (
				<div key={`home-welcome-slider-${item.id}`}>
					<ContainerCustom 
						fluid={true}
						align='center'
						justify='center'
						bgImage={item.imgPath} 
						bgImageSize='cover' 
						bgImagePosition='center center' 
						minHeight='100vh'
						bgRgbaColor={hexToRgb(darkPrimary)} 
						bgRgbaOpacity={.8}
						>
						<Col className='content-wrapper text-light'>
							<img width="250px" src='../images/valore-logo-white.png' alt='Valore intimo main logo' />
							<TextCustom as='h3' size='1.05rem' margin='15px 0 0 0'>{item.title}</TextCustom>
						</Col>
					</ContainerCustom>
				</div>
			))}
		</SliderStyled>
  );
};

export default HomeWelcomeSlider;
