const hexToRgb = (hex) => {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);

  if (normal)
    return normal
      .slice(1)
      .map((e) => parseInt(e, 16))
      .join(", ");

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
  if (shorthand)
    return shorthand
      .slice(1)
      .map((e) => 0x11 * parseInt(e, 16))
      .join(", ");

  return null;
};

export default hexToRgb;
