import { useState, useEffect } from 'react';
import { ContainerCustom, TextCustom, ImageCustom, Timeline } from '../components';
import { Col } from 'react-grid-system';
import { timelineData } from '../data';
import { hexToRgb } from '../functions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const About = () => {
  const theme = useSelector(state => state.settings.theme);
  const { t } = useTranslation();
  const { lightPrimary, darkPrimary, primaryColor } = theme.colors;
  const [timelineDataItems, setTimelineDataItems] = useState([]);

  useEffect(() => {
    const data = timelineData(t);
    setTimelineDataItems(data);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		
		return () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });;
  }, [t]);

  return (
    <>
      {/* About - welcome container */}
      <ContainerCustom 
        fluid={true}
        align='center'
        justify='center'
        bgImage='/images/about-cover.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        minHeight='50vh'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
        >
        <Col className='text-light text-center'>
          <TextCustom as='h1' size='2.5rem' margin='0 0 10px 0'>{t('about-us-welcome-title')} <TextCustom as='span' color={primaryColor}>Valore Intimo</TextCustom></TextCustom>
          <TextCustom as='h3' size='1.05rem'>{t('about-us-welcome-description')}</TextCustom>
        </Col>
      </ContainerCustom>

      {/* About us - basic */}
      <ContainerCustom padding='50px 0px' justify='between' bgColor={lightPrimary}>
        <Col xs={12}>
          <TextCustom as='h1' size='2.2rem'>
            {t('about-us-basic-title')} <TextCustom as='span' color={primaryColor}>Valore Intimo</TextCustom>
          </TextCustom>
        </Col>

        <Col lg={7} xl={8}>
          <TextCustom>{t('about-us-basic-paragraph-1')}</TextCustom>

          <TextCustom>{t('about-us-basic-paragraph-2')}</TextCustom>

          <TextCustom>{t('about-us-basic-paragraph-3')}</TextCustom>

          <TextCustom>{t('about-us-basic-paragraph-4')}</TextCustom>
        </Col>
        <Col lg={5} xl={3}>
          <ImageCustom src='/images/about-timeline.jpg' alt='Valore Intimo - about Valore Intimo' width='100%' borderColor={primaryColor} />
        </Col>
      </ContainerCustom>

      {/* About us- timeline */}

      <ContainerCustom 
        fluid={true}
        bgImage='/images/about-timeline.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        bgImageAttachment='fixed'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
        padding='50px 0px' 
        color={lightPrimary}
      >
        <Col>
          <ContainerCustom bgColor='transparent' color={lightPrimary}>
            <TextCustom as='h1' size='2.2rem'>
              {t('about-us-timeline-title')} <TextCustom as='span' color={primaryColor}>{t('about-us-timeline-title-span')}</TextCustom>
            </TextCustom>

            <Timeline timelineData={timelineDataItems} />
          </ContainerCustom>
        </Col>
      </ContainerCustom>
    </>
  );
};

export default About;
