import { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ContainerCustom, TextCustom } from "../components";
import { Col } from "react-grid-system";
import { hexToRgb } from "../functions";
import { useSelector } from "react-redux";

const Wrapper = styled.section``;

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.settings.theme);
  const { darkPrimary } = theme.colors;

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		
		return () => window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <Wrapper>
      {/* Terms & Conditions - welcome container */}
      <ContainerCustom
				fluid={true}
        align='center'
        justify='center'
        bgImage='/images/gallery-cover.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        minHeight='300px'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
      >
        <Col className="text-light text-center description">
          <TextCustom as="h1" size="2.5rem" margin="0 0 10px 0">
            {t("tc-title")}
          </TextCustom>
          <TextCustom as="h3" size="1.05rem">
            {t("tc-leading-message")}
          </TextCustom>
        </Col>
      </ContainerCustom>

      <ContainerCustom padding="50px 0px" direction="column">
				<Col xs={12}>
					<TextCustom as="h2" size="2rem" margin="0 0 10px 0">
						1. {t("tc-sending-package-title")}
					</TextCustom>
					
					<TextCustom>{t("tc-sending-package-description-1")}</TextCustom>
					<TextCustom>{t("tc-sending-package-description-2")}</TextCustom>
				</Col>

				<Col xs={12}>
					<TextCustom as="h2" size="2rem" margin="0 0 10px 0">
						2. {t("tc-complaints-title")}
					</TextCustom>
					
					<TextCustom>{t("tc-complaints-description-1")}</TextCustom>
					<TextCustom>{t("tc-complaints-description-2")}</TextCustom>
				</Col>

				<Col xs={12}>
					<TextCustom as="h2" size="2rem" margin="0 0 10px 0">
						3. {t("tc-return-and-exchange-title")}
					</TextCustom>
					
					<TextCustom>{t("tc-return-and-exchange-description-1")}</TextCustom>
					<TextCustom>{t("tc-return-and-exchange-description-2")}</TextCustom>
				</Col>
      </ContainerCustom>
    </Wrapper>
  );
};

export default TermsAndConditions;
