import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ButtonCustom, TextCustom } from '../components';
import { hexToRgb } from '../functions';

const ErrorStyled = styled.section`
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.darkPrimary};
  color: ${props => props.theme.colors.lightPrimary};

  h1 {
    margin-bottom: 20px;
  }

  button {
    margin-top: 30px;

    &:hover {
      transition: .3s;
      transform: scale(1.05);
      background-color: ${props => props.theme.colors.primaryColor};
      color: ${props => props.theme.colors.darkPrimary};
      -webkit-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
      -moz-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
      box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
    }
  }
`;

const Error = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useSelector(state => state.settings.theme);
  const { darkPrimary, lightPrimary, primaryColor } = theme.colors;

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const goToHome = () => {
    navigate('/');
  }

  return (
    <ErrorStyled>
      <TextCustom as='h1' size='7rem' color={lightPrimary}>
          4<TextCustom as='span' color={primaryColor}>0</TextCustom>4
      </TextCustom>
      <p>{t("error-not-found")}</p>
      <ButtonCustom
        as='button'
        backgroundColor={lightPrimary}
        color={darkPrimary}
        textTransform="uppercase"
        onClick={() => goToHome()}
      >
        {t("error-home-button")}
      </ButtonCustom>
    </ErrorStyled>
  );
};
export default Error;
