import {
  ScissorsIcon,
  StarShootingIcon,
  WeatherPartlyLightingIcon,
  LightbulbOnIcon,
} from "../icons";

export const homeBestSellerData = (primaryColor, t) => [
  {
    icon: <ScissorsIcon width="50px" height="50px" fill={primaryColor} />,
    title: t("home-best-seller-material-title"),
    description: t("home-best-seller-material-description"),
  },
  {
    icon: <StarShootingIcon width="50px" height="50px" fill={primaryColor} />,
    title: t("home-best-seller-quality-title"),
    description: t("home-best-seller-quality-description"),
  },
  {
    icon: (
      <WeatherPartlyLightingIcon
        width="50px"
        height="50px"
        fill={primaryColor}
      />
    ),
    title: t("home-best-seller-adaptability-title"),
    description: t("home-best-seller-adaptability-description"),
  },
  {
    icon: <LightbulbOnIcon width="50px" height="50px" fill={primaryColor} />,
    title: t("home-best-seller-idea-title"),
    description: t("home-best-seller-idea-description"),
  },
];
