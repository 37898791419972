import styled from 'styled-components';
import { hexToRgb } from '../../functions';

const ImageCustomWrapper = styled.div`
  position: relative;

  img {
    border: 1px solid ${props => props.$borderColor || props.theme.colors.darkPrimary};
  }

  &:before, &:after {
    content: '';
    pointer-events: none;
    display: inline-block;
    position: absolute;
    z-index: 10;
    border: 1px solid ${props => props.$borderColor || props.theme.colors.darkPrimary};
  }

  &:before {
    top: -10px;
    right: 10px;
    left: -10px;
    bottom: 14px;
  }

  &:after {
    top: 10px;
    right: -10px;
    left: 10px;
    bottom: -6px;
  }

  &:hover {
    img {
      margin: 1px 0;
      border-width: 0;
      -webkit-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.$borderColor || props.theme.colors.darkPrimary)}, .85)`};
      -moz-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.$borderColor || props.theme.colors.darkPrimary)}, .85)`};
      box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.$borderColor || props.theme.colors.darkPrimary)}, .85)`};
    }
  }

  &:hover::before, &:hover:after {
    transition: .3s;
    inset: 0;
    border-width: 0;
  }
`;

const ImageCustomStyled = styled.img`
  width: ${props => props.$width || 'auto'};
  height: ${props => props.$height || 'auto'};
`;

const ImageCustom = ({ src, alt, width, height, borderColor }) => {
  return (
    <ImageCustomWrapper $borderColor={borderColor}>
      <ImageCustomStyled src={src} alt={alt} $width={width} $height={height} />
    </ImageCustomWrapper>
  );
}

export default ImageCustom;
