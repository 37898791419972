import { useState } from "react";
import styled from "styled-components";
import { ChevronLeftIcon } from "../../icons";

const TogglerStyled = styled.article`
  /* border: 2px solid red; */
  /* border-radius: ${(props) => props.$borderRadius || "50px"}; */
`;

const TogglerTitle = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;

	svg {
		transform: rotate(270deg);
	}

	&.active {
		svg {
			transition: .3s;
			transform: rotate(90deg);
		}
	}
`;

const TogglerBody = styled.div`
	transition: .3s;
	margin-top: 10px;
	padding: 10px;
	background-color: #fff;
	-webkit-box-shadow: inset 0px 10px 10px -10px rgba(0,0,0,0.75);
	-moz-box-shadow: inset 0px 10px 10px -10px rgba(0,0,0,0.75);
	box-shadow: inset 0px 3px 3px -3px rgba(0,0,0,0.25);
`;

const Toggler = ({
	children,
  title,
}) => {
	const [showToggleContent, setShowToggleContent] = useState(false);

	const clickHandler = () => {
		setShowToggleContent(!showToggleContent);
	}

  return (
    <TogglerStyled className='toggler'>
			<TogglerTitle className={`toggler-title ${showToggleContent ? 'active' : ''}`} onClick={() => clickHandler()}>
				{title} 
				<ChevronLeftIcon width='1.2rem' height='1.2rem' />
			</TogglerTitle>
			{showToggleContent && <TogglerBody className='toggler-body'>
				{children}
			</TogglerBody>}
    </TogglerStyled>
  );
};

export default Toggler;
