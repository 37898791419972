const CartCheckoutIcon = ({ width, height, fill }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill}
      height={width ?? "1rem"}
      width={height ?? "1rem"}
    >
      <path d="M.5 1a.5.5 0 000 1h1.11l.401 1.607 1.498 7.985A.5.5 0 004 12h1a2 2 0 100 4 2 2 0 000-4h7a2 2 0 100 4 2 2 0 000-4h1a.5.5 0 00.491-.408l1.5-8A.5.5 0 0014.5 3H2.89l-.405-1.621A.5.5 0 002 1H.5zM6 14a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm-1.646-7.646l-3 3a.5.5 0 01-.708 0l-1.5-1.5a.5.5 0 11.708-.708L8 8.293l2.646-2.647a.5.5 0 01.708.708z" />
    </svg>
  );
};

export default CartCheckoutIcon;
