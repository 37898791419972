import styled from "styled-components";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TextCustom, ProductMark } from '../'

const ShopCardImageStyled = styled.div`

`

const ShopCardTitleStyled = styled.div`
	background-color: white;
	padding: 15px;

	h4 {
		margin: 0;
		text-decoration: none;
		color: ${props => props.theme.colors.darkPrimary};

		&:focus, &:hover, &:visited, &:link, &:active {
			text-decoration: none;
		}
	}
`

const ShopCardStyled = styled.article`
	position: relative;
	overflow: hidden;
	text-align: center;
	margin-bottom: 30px;
	-webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 20px 32px -30px rgba(0,0,0,0.5);
	height: 300px;

	& > a {
		& > div:first-of-type {
			height: 240px;
		}

		& > div:last-child {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 60px;
			padding: 0 10px;
		}
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	&:hover {
		transition: .3s;
		transform: scale(1.02);
		-webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.5);
		-moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.5);
		box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.5);

		h4 {
			transition: .5s;
			color: ${props => props.theme.colors.primaryColor};
		}
	}

	@media (max-width: 576px) {
		max-width: 300px;
		margin: 0 auto 30px auto;
	}
`;

const ProductItemShopCard = ({ linkPath, title, leadingImage, mark }) => {
	const theme = useSelector(state => state.settings.theme);
  const { lightPrimary } = theme.colors;

	let itemMark = <ProductMark>{mark}</ProductMark>;

	if (mark === 'prodato' || mark === 'sold') {
		itemMark = <ProductMark backgroundColor='#cc0000' color={lightPrimary}>{mark}</ProductMark>;
	}

  return (
    <ShopCardStyled>
			{mark && itemMark}
      <Link to={linkPath} style={{ textDecoration: 'none'}}>
				<ShopCardImageStyled>
					<img src={leadingImage} alt={`Valore intimo shop product - ${title}`} />
				</ShopCardImageStyled>

				<ShopCardTitleStyled>
					<TextCustom as='h4' size='1rem'>{title}</TextCustom>
				</ShopCardTitleStyled>
			</Link>
    </ShopCardStyled>
  );
};

export default ProductItemShopCard;
