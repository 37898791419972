import { useState, useEffect } from 'react';
import styled from "styled-components";
import { UpIcon } from "../../icons";
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../functions';

const ScrollToTopStyled = styled.a`
	${props => props.$offset > 50 && `
		cursor: pointer;
		position: fixed;
		z-index: 1;
		bottom: 15px;
		right: 15px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		text-decoration: none;
		background-color: rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .85);
		-webkit-box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
		-moz-box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
		box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);

		&:hover {
			transition: .3s;
			transform: scale(1.05);
			-webkit-box-shadow: 0px 0px 5px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
			-moz-box-shadow: 0px 0px 5px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
			box-shadow: 0px 0px 5px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5);
		}
	`}
`;

const ScrollToTopArrow = () => {
	const theme = useSelector(state => state.settings.theme);
  const { primaryColor } = theme.colors;
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		// Clean up code
		window.removeEventListener('scroll', onScroll);
	
		window.addEventListener('scroll', onScroll, { passive: true });
		
		return () => window.removeEventListener('scroll', onScroll);
	}, []);
	
	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}

	return (
		<ScrollToTopStyled onClick={() => scrollToTop()} $offset={offset}>
			<UpIcon width='20px' height='20px' fill={primaryColor} />
		</ScrollToTopStyled>
	);
};

export default ScrollToTopArrow;
