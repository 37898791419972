const PhotoAlbumIcon = ({ width, height, fill }) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill={fill}
        height={width ?? '1rem'}
        width={height ?? '1rem'}
      >
        <path d="M20 2H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.988 5 19.806 5 19s.55-.988 1.012-1H21V3a1 1 0 00-1-1zM9.503 5a1.503 1.503 0 110 3.006 1.503 1.503 0 010-3.006zM12 13H7l3-3 1.5 1.399L14.5 8l3.5 5h-6z" />
      </svg>
    );
  }
  
  export default PhotoAlbumIcon;
  