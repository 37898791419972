import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonCustom, ContainerCustom, TextCustom } from '../components';
import { Container, Row, Col } from 'react-grid-system';
import { hexToRgb } from '../functions';
import { SendMessageIcon } from '../icons';
import { useSelector, useDispatch } from 'react-redux';
import { triggerSnackbar } from '../redux-slices/snackbarSlice';
import { validateEmail } from '../functions/';
import axios from 'axios';

const inputCommonStyle = css`
  padding: 10px;
  background-color: transparent;
  color: ${props => props.theme.colors.lightPrimary};
  border: 1px solid ${props => props.theme.colors.lightPrimary};
  font-size: 1.1rem;

  &:focus {
    outline: none;
    transition: .3s;
    border: 1px solid ${props => props.theme.colors.primaryColor};
  }
`;

const FormWrapper = styled(Container)`
  margin-top: 50px;

  a, button {
    margin: 30px 0 0 0;

    &:hover {
      transition: .3s;
      transform: scale(1.05);
      -webkit-box-shadow: 0px 0px 15px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
      -moz-box-shadow: 0px 0px 15px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
      box-shadow: 0px 0px 15px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
    }
  }
`;

const FormControl = styled.div`
  position: relative;
  margin: 0 0 15px 0;
  text-align: left;

  label {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 1rem;
  }

  & > p {
    position: absolute;
    bottom: -15px;
  }
`;

const FormInputStyled = styled.input`
  ${inputCommonStyle}
	width: calc(100% - 22px);
`;

const FormTextareaStyled = styled.textarea`
	${inputCommonStyle}
  width: calc(100% - 22px);
	height: 197px;
  resize: none;
`;

const Contact = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const theme = useSelector(state => state.settings.theme);
  const { darkPrimary, primaryColor } = theme.colors;

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [subject, setSubject] = useState('');
  const [subjectError, setSubjectError] = useState(false);

  const [message, setMessage] = useState(''); 
  const [messageError, setMessageError] = useState(false); 

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		
		return () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });;
  }, []);

  const postMessage = async () => {
    try {
      // const response = await axios.post("http://localhost:5000/api/sendemail", data);

      const response = await axios.get('https://api.valoreintimo.com/index.php?name=' + name + 
      '&email=' + email + 
      '&subject=' + subject +
      '&message=' + message);

      const snackbarInfo = {
        show: true,
        icon: 'messageSent',
        message: t("contact-message-sent-notification"),
        bgColor: primaryColor,
        color: darkPrimary,
        seconds: 5,
      }
      dispatch(triggerSnackbar(snackbarInfo));
      navigate('/');
    } catch(error) {
      const snackbarInfo = {
        show: true,
        icon: 'error',
        message: error.message,
        bgColor: '#b30f0f',
        color: darkPrimary,
        seconds: 5,
      }
      dispatch(triggerSnackbar(snackbarInfo));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailIsValid = validateEmail(email);

    if (name === '') {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (email === '') {
      setEmailError(true);
    } else {
      if (emailIsValid) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }

    if (subject === '') {
      setSubjectError(true);
    } else {
      setSubjectError(false);
    }

    if (message === '') {
      setMessageError(true);
    } else {
      setMessageError(false);
    }

    if (name !== '' && email !== '' && subject !== '' && message !== '' && emailIsValid) {
      postMessage();
    }
  }

  return (
    <>
      <ContainerCustom 
        fluid={true}
        align='center'
        justify='center'
        direction='column'
        bgImage='/images/contact-cover.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        minHeight='50vh'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
        padding='100px 0'
        >
        <Col xs={12} className='text-light text-center'>
          <TextCustom as='h1' size='2.5rem' margin='0 0 30px 0'><TextCustom as='span' color={primaryColor}>{t("contact-welcome-title")}</TextCustom> {t("contact-welcome-title-span")}</TextCustom>
          <Container>
            <TextCustom as='h3' size='1.05rem'>{t("contact-welcome-description")}</TextCustom>
          </Container>

          <FormWrapper>
            <Row>
              <Col sm={6}>
                <FormControl>
                  <label>{t("contact-name-label")}</label>
                  <FormInputStyled 
                    value={name} 
                    type='text' 
                    placeholder={t("contact-name-placeholder")} 
                    name='name' 
                    maxLength={25}
                    onChange={e => setName(e.target.value)}
                  />
                  {nameError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("contact-name-error")}</TextCustom>}
                </FormControl>
                <FormControl>
                  <label>{t("contact-email-label")}</label>
                  <FormInputStyled 
                    value={email} 
                    type='email' 
                    placeholder={t("contact-email-label")}
                    name='email' 
                    maxLength={40}
                    onChange={e => setEmail(e.target.value)}
                  />
                  {emailError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("contact-email-error")}</TextCustom>}
                </FormControl>
                <FormControl>
                  <label>{t("contact-subject-label")}</label>
                  <FormInputStyled 
                    value={subject} 
                    type='text' 
                    placeholder={t("contact-subject-label")} 
                    name='subject'
                    maxLength={25} 
                    onChange={e => setSubject(e.target.value)}
                  />
                  {subjectError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("contact-subject-error")}</TextCustom>}
                </FormControl>
              </Col>
              <Col sm={6}>
                <FormControl>
                  <label>{t("contact-message-label")}</label>
                  <FormTextareaStyled 
                    value={message} 
                    type='text' 
                    maxLength={400}
                    placeholder={t("contact-message-label")}
                    name='message' 
                    onChange={e => setMessage(e.target.value)}
                  />
                  {messageError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("contact-message-error")}</TextCustom>}
                </FormControl>
              </Col>

              <Col xs={12}>
                <ButtonCustom 
                  as='button'
                  backgroundColor={primaryColor}
                  color={darkPrimary}
                  textTransform='uppercase'
                  onClick={handleSubmit}
                >
                  <SendMessageIcon width={15} height={15} fill={darkPrimary} /> {t("contact-send-button")}
                </ButtonCustom>
              </Col>
            </Row>
          </FormWrapper>
        </Col>
      </ContainerCustom>
    </>
  );
};

export default Contact;
