import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-grid-system'

const ContainerCustomStyled = styled(Container)`
  position: relative;
  min-height: ${props => props.$minHeight || 'auto'};
  background-color: ${props => props.$bgColor || props.theme.colors.lightPrimary};
  color: ${props => props.$color || props.theme.colors.darkPrimary};
  background-image: url(${props => props.$bgImage || 'unset'});
  background-size: ${props => props.$bgImageSize || 'unset'};
  background-position: ${props => props.$bgImagePosition || 'unset'};
  background-attachment: ${props => props.$bgImageAttachment || 'unset'};

  & > div.containerRow {
    position: relative;
    z-index: 1;
    padding: ${props => props.$padding || '0px'};
    min-height: ${props => props.$minHeight || 'auto'};

    & > div {
      padding: 0px;
    }

    .containerRow {
      margin-left: unset !important;
      margin-right: unset !important;
    }
  }

  ${props => (props.$bgRgbaColor || props.bgRgbaOpacity) && `
    & > div.shadow {
      position: absolute;
      z-index: 0;
      inset: 0;
      width: 100%;
      height: 100%;
      min-height: ${props.$minHeight || 'auto'};
      background-color: rgba(${props.$bgRgbaColor}, ${props.$bgRgbaOpacity || 1});
    }

    & > div.containerRow > div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  `}
`

const WrapperContainer = styled.div`
  background-color: ${props => props.bgColor || props.theme.colors.lightPrimary};
  color: ${props => props.color || props.theme.colors.darkPrimary};
`

const ContainerCustom = ({ className, fluid, align, justify, direction, bgColor, color, minHeight, bgImage, bgImagePosition, bgImageSize, bgImageAttachment, bgRgbaColor, bgRgbaOpacity, padding, children }) => {

  const mainContainer = <ContainerCustomStyled 
      className={className}
      fluid={fluid} 
      $bgColor={bgColor} 
      $color={color} 
      $minHeight={minHeight} 
      $bgImage={bgImage} 
      $bgImagePosition={bgImagePosition} 
      $bgImageSize={bgImageSize} 
      $bgImageAttachment={bgImageAttachment}
      $bgRgbaColor={bgRgbaColor} 
      $bgRgbaOpacity={bgRgbaOpacity}
      $padding={padding}
    >
      <Row className='containerRow' align={align} justify={justify} direction={direction}>
        {children}
      </Row>
      <div className='shadow'></div>
  </ContainerCustomStyled>

  return <>
    {fluid ? mainContainer : <WrapperContainer bgColor={bgColor} $color={color} >
      {mainContainer}
    </WrapperContainer>}
  </>
}

export default ContainerCustom
