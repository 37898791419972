import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import styled from "styled-components";
import { hexToRgb } from "../../functions";
import { snackbarEmptyObj, triggerSnackbar } from "../../redux-slices/snackbarSlice";
import { CartAdd, SendMessageIcon, ErrorIcon } from "../../icons";

const SnackbarStyled = styled.article`
	position: fixed;
	z-index: 101;
	bottom: 15px;
	padding: 10px;
	background-color: ${(props) => props.$bgColor || props.theme.colors.primaryColor};
	color: ${(props) => props.$color || props.theme.colors.lightPrimary};
	-webkit-box-shadow: 0px 0px 5px 0px rgba(${props => hexToRgb(props.theme.colors.darkPrimary)}, .5);
	-moz-box-shadow: 0px 0px 5px 0px rgba(${props => hexToRgb(props.theme.colors.darkPrimary)}, .5);
	box-shadow: 0px 0px 5px 0px rgba(${props => hexToRgb(props.theme.colors.darkPrimary)}, .5);
	animation: ${(props) => `snackbarSlideToLeft 1s normal forwards ease-in, snackbarSlideToRight 1s normal forwards ease-out ${props.$seconds}s`};

	@keyframes snackbarSlideToLeft {
		from {
			right: -100%;
		}
		to {
			right: 14px;
		}
	}

	@keyframes snackbarSlideToRight {
		from {
			right: 14px;
		}
		to {
			right: -100%;
		}
	}

	@media(max-width: 350px) {
		left: 15px;
	}
`;

const snackbarIcon = (val, fillColor) => {
	let icon = null;
	switch(val) {
		case 'productAdded':
			icon = <CartAdd width={15} height={15} fill={fillColor} />;
			break;
		case 'messageSent':
			icon = <SendMessageIcon width={15} height={15} fill={fillColor} />;
			break;
		case 'error':
			icon = <ErrorIcon width={15} height={15} fill={fillColor} />;
			break;
		default:
			break;
	}

	return icon;
}

const Snackbar = ({ icon, message, bgColor, color, seconds }) => {
	const dispatch = useDispatch();
	const theme = useSelector(state => state.settings.theme);
  const { darkPrimary } = theme.colors;

	useEffect(() => {
		const timer = setTimeout(() => {
      dispatch(triggerSnackbar(snackbarEmptyObj));
    }, (seconds + 1) * 1000);
    return () => clearTimeout(timer);
	});

  return (
    <SnackbarStyled $bgColor={bgColor} $color={color} $seconds={seconds}>
      {snackbarIcon(icon, darkPrimary)} {message}
    </SnackbarStyled>
  );
};

export default Snackbar;
