import styled from 'styled-components';

const BadgeStyled = styled.span`
  display: inline-block;
  font-size: ${props => props.$size || '.8rem'};
  background-color: ${props => props.$backgroundColor || props.theme.colors.darkPrimary};
  color: ${props => props.$color || props.theme.colors.lightPrimary};
  margin: ${props => props.$margin || '0 5px 0 0'};
  padding: ${props => props.$color || '3px 10px'};
  border-radius: ${props => props.$color || '50px'};
`

const Badge = ({ size, backgroundColor, color, padding, borderRadius, children }) => {
  return <BadgeStyled $size={size} $backgroundColor={backgroundColor} $color={color} $padding={padding} $borderRadius={borderRadius}>
    {children}
  </BadgeStyled>
}

export default Badge
