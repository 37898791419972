import { useState } from 'react';
import styled, { css } from "styled-components";
import { hexToRgb } from '../../functions';

const trianglesCommon = css`
	cursor: pointer;
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
`;

const TrianglesStyled = styled.section`
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: ${props => props.$backgroundColor || props.theme.colors.primaryColor};

	.triangle {
		${trianglesCommon}
		background-position: ${props => props.$backgroundPosition || 'center center'};

		& > div {
			position: relative;
			width: 100%;
			height: 100%;
			background-color: ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, ${props.$opacity || 0.8})`};

			& > article {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				max-width: 200px;
				color: ${props => props.theme.colors.lightPrimary};
			}
		}
	}

	// left
	&.left-active .left:hover {
		transition: .3s;
		clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0 100%, 0 0);

		& > div > article {
			top: calc(50% - 36px);
			bottom: unset;
			left: calc(50% - 100px);
		}

		@media (max-width: 992px) {
			clip-path: polygon(0 41%, 100% 41%, 100% 100%, 0 100%);

			& > div > article {
				display: inline-flex;
				top: calc(70% - 36px);
			}
		}
	}

	&.left-active .left:hover ~ .center {
		transition: .3s;
		clip-path: polygon(100% 0, 50.2% 0, 100% 49.7%);

		& > div > article {
			top: 12%;
			right: 5%;
			bottom: unset;
			left: unset;
		}

		@media (max-width: 992px) {
			clip-path: polygon(0 18.3%,100% 20.3%,100% 40.7%,0 40.7%);

			& > div > article {
				display: none;
			}
		}
	}

	&.left-active .left:hover ~ .right {
		transition: .3s;
		clip-path: polygon(100% 50.3%, 50.3% 100%, 100% 100%);

		@media (max-width: 992px) {
			clip-path: polygon(0 0,100% 0,100% 20%,0 18%);

			& > div > article {
				display: none;
			}
		}
	}

	//center
	&.center-active .center:hover {
		transition: .3s;
		clip-path: polygon(100% 0, 0 0, 50% 100%);

		@media (max-width: 992px) {
			clip-path: polygon(0 18.3%,100% 20.3%,100% 77.7%,0 79.7%);

			& > div > article {
				display: inline-flex;
			}
		}
	}

	&.center-active .center:hover ~ .left {
		transition: .3s;
		clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0 100%, 0 0);

		@media (max-width: 992px) {
			clip-path: polygon(0 80%, 100% 78%, 100% 100%, 0 100%);

			& > div > article {
				display: none;
			}
		}
	}

	&.center-active .center:hover ~ .right {
		transition: .3s;
		clip-path: polygon(100% .3%, 50.2% 100%, 100% 100%);

		@media (max-width: 992px) {
			clip-path: polygon(0 0, 100% 0, 100% 20%, 0 18%);

			& > div > article {
				display: none;
			}
		}
	}

	// right
	&.right-active .right:hover {
		transition: .3s;
		clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%, 0 50%);

		& > div > article {
			top: calc(50% - 36px);
			bottom: unset;
			right: calc(50% - 100px);
		}

		@media (max-width: 992px) {
			clip-path: polygon(0 0,100% 0,100% 60%,0 58%);

			& > div > article {
				display: inline-flex;
				top: calc(30% - 36px);
			}
		}
	}

	&.right-active .center {
		transition: .3s;
		clip-path: polygon(49.7% 0, 0 0, 0 49.7%);

		& > div > article {
			top: 12%;
			left: 5%;
			bottom: unset;
			right: unset;
		}

		@media (max-width: 992px) {
			clip-path: polygon(0 58.3%,100% 60.3%,100% 78%,0 79.7%);

			& > div > article {
				display: none;
			}
		}
	}

	&.right-active .left {
		transition: .3s;
		clip-path: polygon(0 50.3%, 0% 100%, 49.7% 100%);

		@media (max-width: 992px) {
			clip-path: polygon(0 80%,100% 78.3%,100% 100%,0 100%);

			& > div > article {
				display: none;
			}
		}
	}
`;

const TriangleLeft = styled.div`
	transition: .3s;
	background-image: ${props => props.$backgroundImage || 'unset'};
	clip-path: polygon(0 .3%, 0% 100%, 49.8% 100%);
	animation: slideTriangleToRight 1s normal forwards ease-in;

	& > div > article {
		bottom: 12%;
		left: 5%;
	}

	@media (max-width: 992px) {
		clip-path: polygon(0 80%, 100% 78%, 100% 100%, 0 100%);

		& > div > article {
			top: 50px;
			left: calc(50% - 100px);
			bottom: unset;
		}
	}

	@keyframes slideTriangleToRight {
		from {
			left: -100%;
		}
		to {
			left: 0px;
		}
	}
`;

const TriangleCenter = styled.div`
	transition: .3s;
	background-image: ${props => props.$backgroundImage || 'unset'};
	clip-path: polygon(100% 0, 0 0, 50% 100%);
	animation: slideTriangleToBottom 1s normal forwards ease-in;

	& > div > article {
		top: 45%;
		left: calc(50% - 100px);
	}

	@media (max-width: 992px) {
		clip-path: polygon(0 18.3%,100% 20.3%,100% 77.7%,0 79.7%);

		& > div > article {
			top: calc(50% - 36px);
			left: calc(50% - 100px);
			bottom: unset;
		}
	}

	@keyframes slideTriangleToBottom {
		from {
			top: -100%;
		}
		to {
			top: 0px;
		}
	}
`;

const TriangleRight = styled.div`
	transition: .3s;
	right: 0;
	background-image: ${props => props.$backgroundImage || 'unset'};
	clip-path: polygon(100% 0.3%, 50.2% 100%, 100% 100%);
	animation: slideTriangleToLeft 1s normal forwards ease-in;

	& > div > article {
		bottom: 12%;
		right: 5%;
	}

	@media (max-width: 992px) {
		clip-path: polygon(0 0, 100% 0, 100% 20%, 0 18%);

		& > div > article {
			top: unset;
			left: calc(50% - 100px);
			bottom: 50px;
		}
	}

	@keyframes slideTriangleToLeft {
		from {
			right: -100%;
			left: 100%;
		}
		to {
			right: 0%;
			left: 0;
		}
	}
`;

const Triangles = ({ imgLeftUrl, imgCenterUrl, imgRightUrl, contentLeftUrl, contentCenterUrl, contentRightUrl, backgroundColor, backgroundPosition, opacity }) => {
	const [activeTriangle, setActiveTriangle] = useState('center');
	const [activeBackgroundUrl, setActiveBackgroundUrl] = useState(imgCenterUrl);

	const mouseOverTriangleHandler = (activeTriangle, activeBackgroundUrl) => {
		setActiveTriangle(activeTriangle);
		setActiveBackgroundUrl(activeBackgroundUrl);
	};

	const mouseOutTriangleHandler = () => {
		setActiveTriangle('center');
		setActiveBackgroundUrl(imgCenterUrl);
	};

  return (
		<TrianglesStyled 
			className={`${activeTriangle}-active`}
			$backgroundColor={backgroundColor}
			$backgroundPosition={backgroundPosition}
			$opacity={opacity}
		>
			<img width="150px" src='../images/valore-logo-triangle-black.png' alt='Valore intimo triangle logo' />
			<TriangleLeft 
				onMouseOver={() => mouseOverTriangleHandler('left', imgLeftUrl)}
				onMouseOut={() => mouseOutTriangleHandler()}
				className='triangle left' 
				$backgroundImage={`url("${activeBackgroundUrl}")`}
			>
				<div>
					<article>
						{contentLeftUrl}
					</article>
				</div>
			</TriangleLeft>

			<TriangleCenter 
				onMouseOver={() => mouseOverTriangleHandler('center', imgCenterUrl)}
				onMouseOut={() => mouseOutTriangleHandler()}
				className='triangle center'
				$backgroundImage={`url("${activeBackgroundUrl}")`}
			>
				<div>
					<article>
					{contentCenterUrl}
					</article>
				</div>
			</TriangleCenter>

			<TriangleRight 
				onMouseOver={() => mouseOverTriangleHandler('right', imgRightUrl)}
				onMouseOut={() => mouseOutTriangleHandler()}
				className='triangle right' 
				$backgroundImage={`url("${activeBackgroundUrl}")`}
			>
				<div>
					<article>
						{contentRightUrl}
					</article>
				</div>
			</TriangleRight>
		</TrianglesStyled>
	);
};

export default Triangles;
