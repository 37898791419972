import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'react-grid-system';
import { BestSellerDescriptionItemStyled } from '../';
import { homeBestSellerData } from '../../data';
import { useSelector } from 'react-redux';

const BestSellerStyled = styled(Container)`
  width: 60%;
  margin-top: 63px;
  margin-right: 40% !important;
  padding: 0 !important;

  @media (max-width: 992px) {
    width: 100%;
    margin-top: 15px;
    margin-right: 0 !important;
    margin-bottom: 550px;
  }
`;

const BestSeller = () => {
  const theme = useSelector(state => state.settings.theme);
  const { primaryColor } = theme.colors;
  const { t } = useTranslation();
  const [homeBestSellerDataArray, setHomeBestSellerDataArray] = useState([]);

  useEffect(() => {
    const bestSellerData = homeBestSellerData(primaryColor, t);
    setHomeBestSellerDataArray(bestSellerData);
  }, [primaryColor, t]);

  return (
    <BestSellerStyled>
      <Row>
        {homeBestSellerDataArray.map((val, key) => {
          return <BestSellerDescriptionItemStyled key={key} icon={val.icon} title={val.title} description={val.description} />
        })}
      </Row>
    </BestSellerStyled>
  );
};

export default BestSeller;
