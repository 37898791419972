export const homeSliderData = (t) => {
  return [
    { title: t("t-shirt-moja-slavice"), imgSrc: "/images/home-carousel/valore-home-carousel-product-1.jpg", id: "73", mark: t("mark-new") },
    { title: t("underwear-orange"), imgSrc: "/images/home-carousel/valore-home-carousel-product-2.jpg", id: "76", mark: t("mark-new") },
    { title: t("t-shirt-dark-grey"), imgSrc: "/images/home-carousel/valore-home-carousel-product-3.jpg", id: "77", mark: t("mark-new") },
    { title: t("thongs-pink"), imgSrc: "/images/home-carousel/valore-home-carousel-product-4.jpg", id: "12" },
    { title: t("brazilian-amarant"), imgSrc: "/images/home-carousel/valore-home-carousel-product-5.jpg", id: "67" },
    { title: t("brazilian-maroon"), imgSrc: "/images/home-carousel/valore-home-carousel-product-6.jpg", id: "68" },
    { title: t("thongs-orange-light"), imgSrc: "/images/home-carousel/valore-home-carousel-product-7.jpg", id: "8" },
    { title: t("thongs-amarant"), imgSrc: "/images/home-carousel/valore-home-carousel-product-8.jpg", id: "64" },
    { title: t("thongs-dark-green"), imgSrc: "/images/home-carousel/valore-home-carousel-product-9.jpg", id: "9" },
    { title: t("sweatsuit-double-face-orange"), imgSrc: "/images/home-carousel/valore-home-carousel-product-10.jpg", id: "33" },
    { title: t("sweatsuit-double-face-light"), imgSrc: "/images/home-carousel/valore-home-carousel-product-11.jpg", id: "34" },
    { title: t("sweatsuit-double-face-black"), imgSrc: "/images/home-carousel/valore-home-carousel-product-12.jpg", id: "35" },
  ];
};