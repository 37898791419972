import { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { CartIcon, HomeIcon, MaleFemaleChildIcon, PhoneIcon, PhotoAlbumIcon, ShopIcon, StoreSearchIcon } from '../../icons';
import { ListPicker } from '../index';
import { themeData, themeDataIcons, localizationData } from '../../data';
import { hexToRgb, getStorageValue } from '../../functions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updatePrimaryColor, updateSelectedLanguage } from '../../redux-slices/settingsSlice';

const PageHeader = styled(Container)`
  position: fixed !important;
  z-index: 100;
  width: 100%;
  ${props => props.$offset > 50 && `
    transition: .5s;
    background-color: ${props.theme.colors.darkPrimary};
    -webkit-box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)};
    -moz-box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)};
    box-shadow: 0px 0px 2px 0px rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)};
  `}

  & > nav {
    position: relative;
    min-height: 50px;
  }
`;

const HeaderLogo = styled.img`
  width: 100px;
`;

const HeaderMenu = styled.div`
  cursor: pointer;
  position: fixed;
  top: 5px;
  right: 15px;
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  transition: .75s;
  background-color: ${props => props.theme.colors.lightPrimary};

  &:hover {
    transition: .3s;
    -webkit-box-shadow: 0px 0px 15px 0px ${props => `rgba(${hexToRgb(props.theme.colors.lightPrimary)}, .7)`};
    -moz-box-shadow: 0px 0px 15px 0px ${props => `rgba(${hexToRgb(props.theme.colors.lightPrimary)}, .7)`};
    box-shadow: 0px 0px 15px 0px ${props => `rgba(${hexToRgb(props.theme.colors.lightPrimary)}, .7)`};
  }

  &.active {
    transition: .75s;
    background-color: ${props => props.theme.colors.darkPrimary};

    &:hover {
      transition: .3s;
      -webkit-box-shadow: 0px 0px 15px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .7)`};
      -moz-box-shadow: 0px 0px 15px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .7)`};
      box-shadow: 0px 0px 15px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .7)`};
    }
  }
`;

const HeaderMenuBackground = styled.div`
  position: absolute;
  top: -19px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.lightPrimary};
  transition: .75s;
  transform: scale(1);

  &.active {
    transition: .75s;
    transform: scale(200);
  }

  &.active + .menu-content {
    transition: 3s;
    opacity: 1;
    pointer-events: auto;
  }
`;

const HeaderMenuContent = styled.div`
  pointer-events: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('../../images/about-cover.jpg');
  background-size: cover;
  background-position: center center;

  & > div {
    content: '';
    width: 100vw;
    height: 100vh;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => `rgba(${hexToRgb(props.theme.colors.lightPrimary)}, .7)`};
  }
`;

const HeaderMenuList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 992px;
  width: 100%;
  width: calc(100% - 30px);
  margin: 0;
  padding: 0 20px 0 0;
  list-style-type: none;

  & > li:nth-child(1) {
    width: 25%;
  }

  & > li:nth-child(2) {
    width: 48%;
  }

  & > li:nth-child(3) {
    width: 25%;
  }

  & > li:nth-child(4) {
    width: 69%;
  }

  & > li:nth-child(5) {
    width: 30%;
  }

  & > li:nth-child(6) {
    width: 40%;
  }

  & > li:nth-child(7) {
    width: 59%;
  }
`;

const HeaderMenuListItem = styled.li`
  margin-bottom: 1%;

  a {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 80px;
    background: ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .7)`};
    box-shadow: 0 4px 30px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .1)`};
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .7)`};
    color: ${props => props.theme.colors.lightPrimary};
    text-decoration: none;

    .title {
      position: absolute;
      top: calc(50% - 16px);
      font-size: 24px;
    }

    .description {
      opacity: 0;
      position: absolute;
      right: 10px;
      bottom: 10px;
      left: 10px;
    }

    &:hover {
      transition: .3s;
      background: ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .8)`};

      .title {
        transition: .5s;
        position: absolute;
        top: 10px;
        right: 10px;
        left: 10px;
        color: ${props => props.theme.colors.primaryColor};
        font-size: 15px;
      }

      .description {
        transition: 1s;
        opacity: 1;
      }
    }
  }

  @media (max-width: 992px) {
    width: 100% !important;

    a {
      min-height: 33px;
      
      .title, a:hover .title {
        position: relative !important;
        top: unset !important;
        right: unset !important;
        left: unset !important;
        font-size: 24px !important;
      }

      .description {
        display: none;
      }
    }
  }
`;

const HamburgerMenuIcon = styled.span`
  .ham {
    cursor: pointer;
    width: 40px;
    height: 40px;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ham.active {
    .line {
      stroke: ${props => props.theme.colors.primaryColor}
    }
  }
  .hamRotate.active {
    transform: rotate(45deg);
  }
  .hamRotate180.active {
    transform: rotate(180deg);
  }
  .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: ${props => props.theme.colors.darkPrimary};
    stroke-width: 5.5;
    stroke-linecap: round;
  }
  .hamSvg .top {
    stroke-dasharray: 40 172;
  }
  .hamSvg .middle {
    stroke-dasharray: 40 111;
  }
  .hamSvg .bottom {
    stroke-dasharray: 40 172;
  }
  .hamSvg.active .top {
    stroke-dashoffset: -132px;
  }
  .hamSvg.active .middle {
    stroke-dashoffset: -71px;
  }
  .hamSvg.active .bottom {
    stroke-dashoffset: -132px;
  }
`;

const HeaderLanguagesWrapper = styled.div`
  position: fixed;
  top: 5px;
  left: 15px;
`;

const HeaderThemeWrapper = styled.div`
  position: fixed;
  top: 5px;
  left: 70px;
`;

const StyledNavbar = () => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.settings.theme);
  const { lightPrimary, darkPrimary, primaryColor } = theme.colors;
  const [menuClicked, setMenuClicked] = useState(false);
  const [activeReduxTheme, setActiveReduxTheme] = useState();
  const [offset, setOffset] = useState(0);
  const { t } = useTranslation();

  const styledNavbarItems = [
    {
      route: '/',
      title: 'Home',
      description: t('page-description'),
      icon: <HomeIcon fill='#f6f6f6' />
    },
    {
      route: '/about-us',
      title: t('page-about-us'),
      description: t('page-about-us-description'),
      icon: <StoreSearchIcon fill='#f6f6f6' />
    },
    {
      route: '/collections',
      title: t('page-collections'),
      description: t('page-collections-description'),
      icon: <MaleFemaleChildIcon fill='#f6f6f6' />
    },
    {
      route: '/shop',
      title: t('page-shop'),
      description: t('page-shop-description'),
      icon: <ShopIcon fill='#f6f6f6' />
    },
    {
      route: '/cart',
      title: t('page-cart'),
      description: t('page-cart-description'),
      icon: <CartIcon fill='#f6f6f6' />
    },
    {
      route: '/gallery',
      title: t('page-gallery'),
      description: t('page-gallery-description'),
      icon: <PhotoAlbumIcon fill='#f6f6f6' />
    },
    {
      route: '/contact',
      title: t('page-contact'),
      description: t('page-contact-description'),
      icon: <PhoneIcon fill='#f6f6f6' />
    },
  ];

  useEffect(() => {
    const reduxTheme = themeData.find(val => val.value === primaryColor);
    setActiveReduxTheme(reduxTheme);

    const onScroll = () => setOffset(window.pageYOffset);
    // Clean up code
    window.removeEventListener('scroll', onScroll);

    window.addEventListener('scroll', onScroll, { passive: true });
    
    return () => window.removeEventListener('scroll', onScroll);
  }, [primaryColor]);

  const changeLanguage = useCallback(pickedLanguage => {
    dispatch(updateSelectedLanguage(pickedLanguage.value));
  }, [dispatch]);

  const changeTheme = useCallback(pickedTheme => {
    dispatch(updatePrimaryColor(pickedTheme.value));
  }, [dispatch]);

  return (
    <PageHeader $offset={offset} fluid>
      <Row component='nav' justify='between' align='center'>
        <Col>
          <NavLink to='/'>
            <HeaderLogo src='../images/valore-logo-inline-white.png' alt='Valore intimo - inline logo' />
          </NavLink>
        </Col>

        <Col>
          <HeaderMenuBackground className={menuClicked ? 'active' : ''} />
          <HeaderMenuContent className='menu-content'>
            <div>
              <HeaderMenuList>
              {styledNavbarItems.map((navbarItem, key) => {
                return <HeaderMenuListItem key={key} onClick={() => setMenuClicked(false)}>
                  <NavLink to={navbarItem.route} className={({isActive}) => isActive ? 'active' : ''}>
                    <span className='title'>{navbarItem.icon} {navbarItem.title}</span>
                    <span className='description'>{navbarItem.description}</span>
                  </NavLink>    
                </HeaderMenuListItem>
              })}
              </HeaderMenuList>

              <HeaderLanguagesWrapper>
                <ListPicker 
                  activeItem={() => localizationData.find(lang => lang.value === getStorageValue('rs', 'selectedLanguage'))} 
                  onChange={changeLanguage} listItems={localizationData} 
                />
              </HeaderLanguagesWrapper>

              <HeaderThemeWrapper>
                <ListPicker 
                  activeItem={() => themeData.find(color => color.value === getStorageValue('#daa524', 'selectedPrimaryColor'))} 
                  onChange={changeTheme} 
                  listItems={themeData} 
                  listIconItems={themeDataIcons} 
                />
              </HeaderThemeWrapper>
            </div>
          </HeaderMenuContent>
          <HeaderMenu className={menuClicked ? 'active' : ''} onClick={() => setMenuClicked(!menuClicked)}>
            <HamburgerMenuIcon>
              <svg className={`${menuClicked ? 'active' : ''} ham hamSvg`} viewBox="0 0 100 100" width="80">
                <path
                  className="line top"
                  d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272" />
                <path
                  className="line middle"
                  d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272" />
                <path
                  className="line bottom"
                  d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272" />
              </svg>
            </HamburgerMenuIcon>
          </HeaderMenu>
        </Col>
      </Row>
    </PageHeader>
  );
};

export default StyledNavbar;
